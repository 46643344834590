import React, { useState } from "react";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";

//Include Searcher component
import KiLabSearcher from 'components/KiLab/KiLabSearcher';

//Include Searcher component
import KiLabList from 'components/KiLab/KiLabList';

//Include Translation tool
import { useTranslate } from "react-translate";

//Include a particular scss for this view
import 'assets/scss/custom/_kilab.scss';

const KiLab = () => {

    //Invoke to translate lib
    const t = useTranslate('kilab');

    return (
        <Card className="full-height">
                <CardBody>
                        <Card name="body" className="expand-height">
                                <CardHeader>
                                        <CardTitle className="d-flex align-content-center">
                                                <div className="general-card-title-img text-primary mr-2">
                                                    <i className="icon-TP_KI_Logo_White" />
                                                </div>
                                                <div className="general-card-title text-primary" testId="kilab_title">
                                                    {t('title-view')}
                                                </div>
                                        </CardTitle>
                                </CardHeader>

                                <CardBody className="card-min-height">

                                        { /*********************************
                                        Components to render the searcher and list */ }
                                        <KiLabSearcher />
                                        <KiLabList />
                                </CardBody>
                        </Card>                
                </CardBody>
        </Card>
    );
};
export default KiLab;