import React, { useEffect, useState, useRef } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Input, Button, Row, Col, Label, TabPane, CardBody } from 'reactstrap';

// Include Translation tool
import { useTranslate } from "react-translate";

//Text editor
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

//Include the stored comments list
import KiLabEditor_stored_list from "components/KiLab/KiLabEditor_stored_list";
import { useDispatch, useSelector } from 'react-redux';
import { actionSendEarlyReview, actionSaveEarlyDraft } from 'store/actions/kiLabActions';
import { actionSweetAlert } from 'store/actions/commonActions';
import ReactTooltip from 'react-tooltip';
import { isValidInput } from 'helpers/misc';
import { actionSubmitKilabSurvey } from 'store/actions/kiLabActions';

const sanitizeHtmlReview = (html) => {
    //Decode HTMLEntities
    let txt = document.createElement("textarea");
    txt.innerHTML = html;
    //Delete HTML tags
    return txt.value.replace(/(?![^>]+\b(?:item|range)=)(<[/]*[a-z]+.*?>)/gi, "");
};

const KiLabEditor_comment = ({ setMasterEdited, masterEdited, canSend, setCanSend, review, setReview }) => {

    //Translation library
    const tEditor = useTranslate('kilab-editor');
    const tMedicalReview = useTranslate('medical-review');
    const tLabDetail = useTranslate('laboratory-detail');
    const study = useSelector(state => state.kiLabReducer.study);
    const isLoadingAI = useSelector((state) => state.kiLabReducer.loadingAiReview);
    const context = useSelector(state => state.kiLabReducer.context);
    const practiceId = useSelector(state => state.practicesReducer.practice._id);
    const dispatch = useDispatch();
    const analytes = study && study.study_data ? JSON.parse(study.study_data.folgen_values) : [];

    const editedInfo = useRef();
    const timeoutHandle = useRef();
    const savingTimeoutHandle = useRef();
    const [edited, setEdited] = useState(false);
    const [saving, setSaving] = useState(0);
    const [commentType, setCommentType] = useState(Object.keys(study).length && study.draft ? study.draft.comment_type : 0);
    const [canVote, _setCanVote] = useState(false);
    

    //Proxy function
    const setCanVote = (val) => {
        setMasterEdited(false);
        _setCanVote(val);
    }

    const showTemporalSavedIcon = () => {
        setSaving(2);
        setEdited(false);
        savingTimeoutHandle.current = setTimeout(() => {
            setSaving(0);
            savingTimeoutHandle.current = null;
        }, 2400);
    }

    // Execute when the component is mounted
    useEffect(() => {
        
    }, []);

    useEffect(() => {
        if (saving === 1) {
            showTemporalSavedIcon();
        }

        setReview(study.draft ? study.draft.text : "");
        setCommentType(Object.keys(study).length && study.draft ? study.draft.comment_type : 0);

        //Send if the study got updated and brought an AI draft
        if (study && study.draft && study.draft.comment_type === 1 && masterEdited) {
            setCanSend(false);
            setCanVote(true);
        }
    }, [study]);


    useEffect(() => {
        if (edited) {
            if (timeoutHandle.current) {
                clearTimeout(timeoutHandle.current);
            }
            if (savingTimeoutHandle.current) {
                clearTimeout(savingTimeoutHandle.current);
            }

            //Set saving indicator at phase 1
            setSaving(1);
    
            timeoutHandle.current = setTimeout(() => {
                saveDraft(review);
                setCanSend(true);
                setMasterEdited(true);
                timeoutHandle.current = null;
            }, 1800);
        }
    }, [review, edited]);

    //Get options from radio fields
    const getOptionsValue = () => {
        let option1, option2;

        for (let elem of document.getElementsByName("survey_completely")) {
            if (elem.checked) {
                option1 = elem.value;
            }
        }

        for (let elem of document.getElementsByName("survey_right")) {
            if (elem.checked) {
                option2 = elem.value;
            }
        }

        if (!option1) {
            option1 = context.survey_completely;
        }

        if (!option2) {
            option2 = context.survey_correct;
        }

        return [option1, option2];
    }

    //Get options from radio fields
    const checkOptions = () => {
        let option1, option2 = null;

        for (let elem of document.getElementsByName("survey_completely")) {
            if (elem.checked) {
                option1 = elem.value;
            }
        }

        for (let elem of document.getElementsByName("survey_right")) {
            if (elem.checked) {
                option2 = elem.value;
            }
        }

        return (option1 && option2) ? true : false;
    }

    const onSubmit = async () => {

        if (checkOptions()) {
            const [op1, op2] = getOptionsValue();
            saveDraft(review);
            dispatch(actionSubmitKilabSurvey(practiceId, op1, op2));
            setCanVote(false);
            setCanSend(true);
            setMasterEdited(false);
        } else {
            dispatch(
                actionSweetAlert(
                    <SweetAlert
                        custom
                        customIcon="/General/icons/warning-sign-orange.svg"
                        title={tEditor('missing-options')}
                        onConfirm={() => {}}
                        customButtons={
                            <>
                                {/*Custom buttons for more control*/}
                                <Button
                                    className="mb-3"
                                    color="info"
                                    size="lg"
                                    testId="missing_options_ok"
                                    onClick={() => {
                                        dispatch(actionSweetAlert(null));
                                    }}
                                >
                                    {tEditor('btn-yes')}
                                </Button>
                            </>
                        }
                    >
                        <div testId="missing_options_exp">{tEditor('missing-options-exp')}</div>
                    </SweetAlert>
                )
            );
        }
    };

    const saveDraft = (review) => {
        if (timeoutHandle.current) {
            clearTimeout(timeoutHandle.current);
        }
        const [op1, op2] = getOptionsValue();

        dispatch(actionSaveEarlyDraft({
            studyId: study._id, 
            review: review, 
            hl7_id: study.study_data._id,
            practiceId, practiceId,
            type: commentType,
            context: {
                ...context,
                survey_completely: op1,
                survey_correct: op2,
            }
        }));
    }

    const saveReview = () => {
        if (timeoutHandle.current) {
            clearTimeout(timeoutHandle.current);
        }
        setMasterEdited(false);
        setCanSend(false);
        setCanVote(false);
        const [op1, op2] = getOptionsValue();
        navigator.clipboard.writeText(sanitizeHtmlReview(review));

        //const value = getOptionsValue();
        dispatch(actionSendEarlyReview({
            studyId: study._id, 
            review: review, 
            hl7_id: study.study_data._id,
            practiceId, practiceId,
            type: commentType,
            context: { //Ver como recibir la pregunta y su valor de forma eficiente
                ...context,
                survey_completely: op1,
                survey_correct: op2, 
                /*
                option_1: {question:"correct", value:op1},
                option_2: {question:"survey_right", value:op2}
                */
            }
        }));
    }

    return (

<TabPane tabId="tab-comment">

        { /*  Comment Editor Secition */ }

        <span className={`autosave-container ${saving === 1 ? "info-color" : saving === 2 ? "success-color" : ""}`}> 
            {edited && saving === 1 ? (
                <>
                    <i className="save-icon icon-hourglass-1"></i> 
                    <span testId="autosave_saving">{tLabDetail('autosave-saving')}</span>
                </>
            ) : saving === 2 ? (
                <>
                    <i className="save-icon icon-active-user"></i> 
                    <span testId="autosave_saved">{tLabDetail('autosave-saved')}</span>
                </>
            ) : (
                <div style={{height: "21px"}}></div>
            )}
        </span>

        <ReactTooltip
            id={`editor-blocked`}
            aria-haspopup="true"
            effect="float"
            type="dark"
            place="bottom"
            className={!canVote ? "d-none" : ""}
        >
            <div testId="editor_blocked_tooltip">{tEditor("editor-tooltip")}</div>
        </ReactTooltip>

        <Row>
            <Col lg={"12"}>
                <div className="review-field" data-tip data-for={"editor-blocked"}>
                    <ReactQuill id="editor"
                        className={canVote ? "ki-editor" : ""}
                        readOnly={canVote || isLoadingAI}
                        disabled={isLoadingAI}
                        modules={{ toolbar: (isLoadingAI || canVote) ? [] : [
                            [{ size: [ 'small', false, 'large', 'huge' ]}],
                            ['bold', 'italic', 'underline'],
                            ['link'],
                            [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
                            ['blockquote'],
                            ['clean']
                        ]}}
                        style={{
                            height: "100%",
                            overflowY: "auto",
                            transitionDuration: "0.3s"
                        }}

                        onKeyDown={(e) => {
                            if (isValidInput(e)) {

                                if (commentType === 1) {
                                    setCommentType(2);
                                }

                                setReview(e.target.innerHTML);
                                editedInfo.current = e.target.innerHTML;
                                setEdited(true);
                                setCanSend(false);
                            }
                        }}

                        onChange={(text) => {
                            
                            setReview(text);
                            editedInfo.current = text;
                        }}
                        value={review ? review : ""}
                    >
                    </ReactQuill>

                    <div className="folgen-values-main-section">
                        <div className="folgen-title">
                            <p>{tEditor("folgen-title")}</p>
                        </div>
                        <div className="folgen-section">
                            {
                            analytes && analytes.length ? analytes.map((fv,i) => {
                                let value = fv["Observation Identifier"].replace(/^([\s]*)\s/, '').split(' ');
                                return (
                                <div className="folgen-pill" key={`edition-folgen-pill-${i}`}>
                                    {`${value[1]} ${value[2]}`}
                                </div>
                                )
                            }) : (<p>{tMedicalReview("all-folgen-values-processed")}</p>)
                            }
                        </div>
                    </div>                    
                </div>
            </Col>
        </Row>


       { /* Survey section */}
        <Row className="ki-report">
            <CardBody>
                {/* Preguntas de evaluación */}
                    <div className="editor-bottom">
                          
                        {canVote ? (
                            <div className='divSurvCont' testId="survey_section">
                                                
                                <Col md={5}>
                                    <Label className="survTitle">{tEditor("survey-comment-complete")}</Label>

                                    <Col md={12} className="text-left cont-surv-radio">
                                        <Label className="mr-4 rad-surv">
                                            <Input type="radio" testId="survey_completely_1" name="survey_completely" value="YES" disabled={!canVote} />{tEditor("yes")}
                                        </Label>
                                        <Label className="mr-4 rad-surv">
                                            <Input type="radio" testId="survey_completely_2" name="survey_completely" value="NO" disabled={!canVote} />{tEditor("no")}
                                        </Label>
                                        <Label className="rad-surv" >
                                            <Input type="radio" testId="survey_completely_3" name="survey_completely" value="I_DONT_KNOW" disabled={!canVote} />{tEditor("dont-know")}
                                        </Label>
                                    </Col>
                                </Col>

                                <Col md={5}>
                                    <Label className="survTitle">{tEditor("survey-comment-correct")}</Label>

                                    <Col md={12} className="text-left  cont-surv-radio">
                                        <Label className="mr-4 rad-surv">
                                            <Input type="radio" testId="survey_right_1" name="survey_right" value="YES" disabled={!canVote} />{tEditor("yes")}
                                        </Label>
                                        <Label className="mr-4 rad-surv">
                                            <Input type="radio" testId="survey_right_2" name="survey_right" value="NO" disabled={!canVote} />{tEditor("no")}
                                        </Label>
                                        <Label className="rad-surv">
                                            <Input type="radio" testId="survey_right_3" name="survey_right" value="I_DONT_KNOW" disabled={!canVote} />{tEditor("dont-know")}
                                        </Label>
                                    </Col>
                                </Col>

                                <Col md={1}>
                                    <Button
                                        onClick={(e) => {
                                            e.preventDefault();
                                            onSubmit();
                                        }}
                                        testId="submit_vote_button"
                                        className="btn btn-success"
                                    >
                                        {tEditor("vote")}
                                    </Button>
                                </Col>
                            </div>
                            ) : (<></>)}

                        <div className="btnAiContainter">
                            {!canVote ? (
                                <Button 
                                    className="btn btn-success mr-2 btn-ai-call" 
                                    onClick={saveReview}
                                    testId="save_review"
                                    disabled={!canSend || isLoadingAI}
                                >
                                    {tEditor("btn-save-store")}
                                </Button>
                                
                            ) : (<></>)}
                        </div>
                        
                    </div>
            </CardBody>
        </Row>


        { /*********************************
        Component to render de comment tab */ }
        <KiLabEditor_stored_list />


</TabPane>
);
}
export default KiLabEditor_comment;