import { takeEvery, put, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
//Redux Actions
import CONSTANTS from '../CONSTANTS';
import { actionSetHelps } from '../actions/helpsActions';
//Services
import HelpService from '../../plugins/TS-lib-utils-public/services/HelpService';
HelpService.setBaseUrl("/translations");

/**
 * Get all helps
 */
function* sagaHelps() {
  try {
    const { data } = yield call(HelpService.getKeyList,"web-doctors",-1);
    yield put(actionSetHelps(data));
  } catch (error) {
    console.log(error);
  }
}

export function* helpsSaga() {
  console.log('*Main Helps Saga');
  yield takeEvery(CONSTANTS.HELPS_GET, sagaHelps);
}
