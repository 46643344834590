import CONSTANTS from '../CONSTANTS';

const initialState = {
  studies: [],
  page: 0,
  lastPage: 1,
  limit: 10,
  study: {},
  context: {},
  loadingAiReview: false,
  aiGenerationError: false,
  loadingStudy: false,
  loadingStudyList: false,
  showSentModal: false,
  querySearch: "",
  comingFromDetailView: false,
}
const kiLabReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    
    case CONSTANTS.LAB_EARLY_SET_LIST: 
      return {...state, studies: action.studies.data}
    case CONSTANTS.LAB_EARLY_SET_STUDY: 
      return {...state, study: action.study, loadingAiReview: false}
    case CONSTANTS.LAB_EARLY_SET_QUERY:
      return {...state, querySearch: action.query}
    case CONSTANTS.LAB_EARLY_COMING_FROM_DETAILS:
      return {...state, comingFromDetailView: action.val}
    case CONSTANTS.LAB_EARLY_SET_CONTEXT:
      return {...state, context: {...action.context}}
    case CONSTANTS.LAB_EARLY_SET_LOADING_STUDY:
      return {...state, loadingStudy: action.val}
    case CONSTANTS.LAB_EARLY_SET_LOADING_AI:
      return {...state, loadingAiReview: action.val}
    case CONSTANTS.LAB_EARLY_SET_AI_GEN_ERROR:
      return {...state, aiGenerationError: action.val}

    case CONSTANTS.LAB_EARLY_UPDATE_LOCAL_STUDY: {
      const studies = state.studies;
      const index = studies.map(s => s._id).indexOf(action.studyId);

      if (index > -1) {
        studies[index].responsible = action.doctor;

        return {
          ...state,
          studies: [...studies]
        }
      }
    }
      



    default:
      return state;
  }
};

export default kiLabReducer;
