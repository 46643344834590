import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Col,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Row,
    CardBody,
    Label
} from "reactstrap";
import { useTranslate } from "react-translate";
import SweetAlert from 'react-bootstrap-sweetalert';
import { actionSweetAlert } from 'store/actions/commonActions';
import ReactTooltip from 'react-tooltip';

import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';
import { 
    actionUpdateReview, 
    actionMarkAIComment, 
    actionSendReview, 
    actionSetSentModal,
    actionSaveTemplateLocalData,
    actionSubmitLabSurvey
} from "store/actions/laboratoryActions";
import Icon from "components/Icons/Icon";
import { sanitizeHtmlReview } from "helpers/misc";
import { isValidInput } from 'helpers/misc';

//Get options from survey radio fields
const getSurveyOptionsValue = () => {
    let option1, option2;

    for (let elem of document.getElementsByName("survey_completely")) {
        if (elem.checked) {
            option1 = elem.value;
        }
    }

    for (let elem of document.getElementsByName("survey_right")) {
        if (elem.checked) {
            option2 = elem.value;
        }
    }

    return [option1, option2];
}


const DoctorReviewField = ({ study, reportId, review, setReview, aiFlag, setAiFlag, readAiComment, setReadAiComment, systemTemplates}) => {
    const tMedicalReview = useTranslate('medical-review');
    const tLabDetail = useTranslate('laboratory-detail');
    const tEditor = useTranslate('kilab-editor');
    const dispatch = useDispatch();
    const { doctorInfo } = useSelector(state=>state.userDataReducer);
    const user = useSelector(state => state.userReducer);
    const { loadingAiReview, showSentModal, canVote } = useSelector(state => state.laboratoryReducer);
    const { practice } = useSelector(state => state.practicesReducer);
    const [backupReview, setBackupReview] = useState('');
    const [edited, setEdited] = useState(false);
    const [canSend, setCanSend] = useState(false);
    const [saving, setSaving] = useState(0);
    const [selectedTemplate, setSelectedTemplate] = useState("");
    
    const aiTemplate = study?.ai_review?.content ? study?.ai_review?.content : "";
    const currentFolgenValues = study && study.metadata && study.metadata.folgenValues 
    ? JSON.parse(study?.metadata?.folgenValues) 
    : null;

    //constant to translate ai-comment translations to a base tag
    const aiBaseTag = "ai-comment";

    const timeoutHandle = useRef();
    const savingTimeoutHandle = useRef();
    const editedInfo = useRef();
    const isMounted = useRef(false);

    /** Modal logic */
    const [modal, setModal] = useState(false);
    const [aiModal, setAiModal] = useState(false);

    const readAI = () => {
        //Signal parent component to delete 'new review' text
        setReadAiComment(true);
        //Send signal to backend to read ai comment
        dispatch(actionMarkAIComment(reportId, study.doctor_id, practice._id));
    }
    

    const showTemporalSavedIcon = () => {
        setSaving(2);
        setEdited(false);
        savingTimeoutHandle.current = setTimeout(() => {
            setSaving(0);
            savingTimeoutHandle.current = null;
        }, 2400);
    }

    const toggleModal = (e) => {

        if (timeoutHandle.current) {
            //Save pending review with the previously selected template
            updateReview(backupReview, true, false, selectedTemplate);
            clearTimeout(timeoutHandle.current);
            timeoutHandle.current = null;
        }
        
        if (e && e.target.value) {
            setBackupReview(e.target.value);
        }

        const templateValue = getOptionsValue();
        const templateData = study?.templates?.find(te => te.name === templateValue);

        setAiModal(templateValue === aiBaseTag);
        if (!study?.ai_review?.readed && templateValue === aiBaseTag) {
            readAI();
        }

        if (edited) {
            clearTimeout(timeoutHandle.current);
            setModal(false);
            setReview(templateData ? templateData.content : e.target.value);
            setBackupReview(null);
            setEdited(false);
            editedInfo.current = null;
        } else {
            setReview(templateData ? templateData.content : e.target.value);
        }
    };

    const selectOption = (name) => {
        const t = document.getElementById("template-select");
        let index = 0;
        for (let i = 0; i < t.options.length; i++){
            t.options[i].selected = t.options[i].text === name;
            if (t.options[i].text === name) {
                index = i;
            }
        }
        setAiModal(name === aiBaseTag);
        
        if (name === aiBaseTag) {
            readAI();
        }
    }

    /** Main effects */
    useEffect(() => {
        setReview(!study.ai_review_error &&
            (!study?.reviews?.length || !study.reviews[0].content?.length) && 
            study?.ai_review?.content 
            ? study?.ai_review?.content 
            : study?.reviews?.length && study?.reviews[0]?.content?.length
            ? study?.reviews[0]?.content
            : review);

        if (!study.ai_review_error &&
            (!study?.reviews?.length || !study.reviews[0].content?.length) && 
            study?.ai_review?.content
        ) {
            selectOption("ai-comment");
        } else if (study?.reviews[0]?.template?.name)  {
            selectOption(study?.reviews[0]?.template?.name);
        }
        
        setAiModal(getOptionsValue() === aiBaseTag);

        return () => {
            if (timeoutHandle.current) {
                clearTimeout(timeoutHandle.current);
                updateReview(editedInfo.current, true);
            }

            if (savingTimeoutHandle.current) {
                clearTimeout(savingTimeoutHandle.current);
            }
        }
    }, []);

    useEffect(() => {
        //Select AI option and read when the button to see the ai review was pressed
        if (readAiComment === true && !study?.ai_review?.readed) {
            selectOption("ai-comment");
        }
    }, [readAiComment]);

    useEffect(() => {
        if (saving === 1) {
            //Set saving indicator at phase 2 if the saving timer ended
            showTemporalSavedIcon();
        }

        const templateValue = getOptionsValue();
        const templateData = study?.templates?.find(te => te.name === templateValue);

        if (!study?.ai_review?.readed && templateValue === aiBaseTag) {
            readAI();
        }

        if (!isMounted.current) {
            isMounted.current = true;
            setReview(!study.ai_review_error &&
                (!study?.reviews?.length || !study.reviews[0].content?.length) && 
                study?.ai_review?.content 
                ? study?.ai_review?.content 
                : study?.reviews?.length && study?.reviews[0]?.content?.length
                ? study?.reviews[0]?.content
                : review);
        } else {
            setReview(templateData?.content ? templateData.content 
                : !study.ai_review_error &&
                (!study?.reviews?.length || !study.reviews[0].content?.length) && 
                study?.ai_review?.content 
                ? study?.ai_review?.content 
                : study?.reviews?.length && study?.reviews[0]?.content?.length
                ? study?.reviews[0]?.content
                : review
            );
        }
    }, [study]);

    useEffect(() => {
        if (aiTemplate.length && !study.ai_review_error) {
            if (study?.ai_review?.content?.length && !study?.ai_review?.readed) {
                selectOption("ai-comment");
            }
        }
    }, [review, loadingAiReview]);

    useEffect(() => {
        if (edited) {
            if (timeoutHandle.current) {
                clearTimeout(timeoutHandle.current);
            }
            if (savingTimeoutHandle.current) {
                clearTimeout(savingTimeoutHandle.current);
            }

            //Set saving indicator at phase 1
            setSaving(1);
            setBackupReview(review);
            setSelectedTemplate(getOptionsValue());
    
            timeoutHandle.current = setTimeout(() => {
                updateReview(review, true);
                timeoutHandle.current = null;
            }, 1800);
        }
    }, [review, edited]);

    //Effect to change AI comment
    useEffect(() => {
        if (review) {
            let reviewSanitized = sanitizeHtmlReview(review);
            if (edited) {
                setAiFlag(!(reviewSanitized !== aiTemplate));
            } else if (reviewSanitized === aiTemplate) {
                setAiFlag(true);
            }
        }
    }, [edited, review]);

    const getOptionsValue = () => {
        const t = document.getElementById("template-select");
        return t.options[t.selectedIndex].text === tMedicalReview('phrase-ai-template') ? aiBaseTag : t.options[t.selectedIndex].text;
    };

    const updateReview = (review, skipModal = false, makeDraft = false, templateName = null) => {
        if (timeoutHandle.current) {
            clearTimeout(timeoutHandle.current);
        }
        const value = getOptionsValue();
        dispatch(actionSaveTemplateLocalData(templateName ? templateName : value, review));
        dispatch(actionUpdateReview(reportId, review, study.metadata._id, templateName ? templateName : value, study.doctor_id, practice._id, aiFlag, aiModal, skipModal, makeDraft));
    }

    const sendReview = (billing = false) => {
        if (timeoutHandle.current) {
            clearTimeout(timeoutHandle.current);
        }
        const value = getOptionsValue();
        dispatch(actionSendReview(reportId, review, study.metadata._id, value, study.doctor_id, practice._id, billing, aiFlag, aiModal));
    }

    const aiTemplateFunc = () => {
        let aiComment = study?.templates?.find(t => t.name === aiBaseTag);
        let aiReviewDate = new Date(study?.ai_review?.created_at).getTime()
        return study && study.ai_review ? 
            <option 
                value={new Date(aiComment?.updated_at).getTime() > aiReviewDate ? aiComment.content : study?.ai_review?.content} 
                key="KI-Kommentar"
                selected={study.ai_review && study.reviews[0] && (!study.reviews[0].template || study.reviews[0].template && study.reviews[0].template.name === aiBaseTag)}
                >
                {tMedicalReview('phrase-ai-template')}
            </option>
        : <></>
    }


    const submitSurvey = () => {
        const [op1, op2] = getSurveyOptionsValue();

        if (op1 && op2) {
            dispatch(actionSubmitLabSurvey(reportId, study.doctor_id, practice._id, {survey_completely: op1, survey_correct: op2}));
        } else {
            dispatch(
                actionSweetAlert(
                    <SweetAlert
                        custom
                        customIcon="/General/icons/warning-sign-orange.svg"
                        title={tEditor('missing-options')}
                        onConfirm={() => {}}
                        customButtons={
                            <>
                                {/*Custom buttons for more control*/}
                                <Button
                                    className="mb-3"
                                    color="info"
                                    size="lg"
                                    testId="missing_options_ok"
                                    onClick={() => {
                                        dispatch(actionSweetAlert(null));
                                    }}
                                >
                                    {tEditor('btn-yes')}
                                </Button>
                            </>
                        }
                    >
                        <div testId="missing_options_exp">{tEditor('missing-options-exp')}</div>
                    </SweetAlert>
                )
            );
        }
    }

    return (
    <>
        <div className="template-container ml-1 mr-1 d-flex justify-content-between">
            <div className="template-picker">

                <div className="template-section">
                    <Input 
                        type="select" 
                        testId="template-selector" 
                        readOnly={loadingAiReview || canVote} 
                        disabled={loadingAiReview || canVote} 
                        onChange={toggleModal} id="template-select"
                    >

                        {aiTemplateFunc()}

                        <option value=" " key="empty-template">
                            {tMedicalReview('phrase-no-template')}
                        </option>

                        {systemTemplates && systemTemplates.length && systemTemplates.map(t => {
                            return (
                                <option value={t.text} key={t.name} selected={
                                    study?.reviews[0] && study?.reviews[0]?.template &&
                                    t.name === study?.reviews[0]?.template?.name}>
                                    {t.name}
                                </option>
                            )
                        })}
                        {doctorInfo?.personal_templates?.length && doctorInfo?.personal_templates.map(t => {
                            return (
                                <option value={t.text} key={t.name} selected={
                                    study?.reviews[0] && study?.reviews[0]?.template &&
                                    t.name === study?.reviews[0]?.template?.name}>
                                    {t.name}
                                </option>
                            )
                        })}
                    </Input>
                </div>

                <Modal isOpen={modal} toggle={toggleModal} >
                    <ModalHeader toggle={toggleModal}>{tMedicalReview('title-change-template')}</ModalHeader>
                    <ModalBody>
                        {tMedicalReview('notice-lose-data')}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="info" onClick={() => {
                                clearTimeout(timeoutHandle.current);
                                setModal(false);
                                setReview(backupReview);
                                setBackupReview(null);
                                setEdited(false);
                                updateReview(backupReview);
                                editedInfo.current = null;
                            }}>
                            {tMedicalReview('btn-ok')}
                        </Button>{' '}
                        <Button color="secondary" onClick={() => {setModal(false)}}>
                        {tMedicalReview('btn-cancel')}
                        </Button>
                    </ModalFooter>
                </Modal>


                <Modal isOpen={showSentModal} >
                    <ModalHeader toggle={() => {
                        dispatch(actionSetSentModal(false));
                        }}>
                            Info
                        </ModalHeader>
                    <ModalBody>
                    {tLabDetail("review-saved")}
                    </ModalBody>
                </Modal>

                {study?.reviews[0]?.updated_at && (
                    <span className="timestamp-text">
                        Stand: {moment(study?.reviews[0]?.updated_at).format(`${user.dateFormat} HH:mm`)}
                    </span>
                )}

                <span className={`autosave-container ${saving === 1 ? "info-color" : saving === 2 ? "success-color" : ""}`}> 
                    {edited && saving === 1 && (
                        <>
                            <i className="save-icon icon-hourglass-1"></i> 
                            <span testId="autosave-saving">{tLabDetail('autosave-saving')}</span>
                        </>
                    )}
                    {saving === 2 && (
                        <>
                            <i className="save-icon icon-active-user"></i> 
                            <span testId="autosave-saved">{tLabDetail('autosave-saved')}</span>
                        </>
                    )}
                </span>

            </div>
            
            
            {aiModal && (
                <div>
                    <div className={`KI-Kommentar-${aiFlag? "full" : "edition"}`}>
                        <span>{`${!aiFlag ? 'Arzt' : "KI"}-Kommentar`}</span>
                        {/*<Icon file={`TP_KI_Logo_${aiFlag ? "Blue" : "Orange"}_simple.svg`} width="40px"/>*/}
                    </div>
                </div>
            )}
        </div>

        <ReactTooltip
            id={`editor-blocked`}
            aria-haspopup="true"
            effect="float"
            type="dark"
            place="bottom"
            className={!canVote ? "d-none" : ""}
        >
            <div testId="editor_blocked_tooltip">{tEditor("editor-tooltip")}</div>
        </ReactTooltip>

        <Row>
            <Col lg={"12"}>
                <div className="review-field" data-tip data-for={"editor-blocked"}>
                    <ReactQuill id="editor"
                        readOnly={loadingAiReview || canVote}
                        disabled={loadingAiReview || canVote}
                        modules={{ toolbar: (loadingAiReview || canVote) ? [] : [
                            [{ size: [ 'small', false, 'large', 'huge' ]}],
                            ['bold', 'italic', 'underline'],
                            ['link'],
                            [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
                            ['blockquote'],
                            ['clean']
                        ]}}
                        style={{
                            height: "100%",
                            overflowY: "auto",
                            transitionDuration: "0.3s"
                        }}

                        onKeyDown={(e) => {
                            if (isValidInput(e)) {
                                setReview(e.target.innerHTML);
                                editedInfo.current = e.target.innerHTML;
                                setEdited(true);
                                setCanSend(false);
                            }
                        }}

                        onChange={(text) => {
                            setReview(text);
                            setCanSend(false);
                            editedInfo.current = text;
                        }}
                        value={review ? review : ""}
                    >
                    </ReactQuill>

                    <div className="folgen-values-main-section">
                        <div className="folgen-title">
                            <p>{tMedicalReview("folgen-title")}</p>
                        </div>
                        <div className="folgen-section">
                            {!loadingAiReview && currentFolgenValues && currentFolgenValues.length ? currentFolgenValues.map((fv,i) => {
                                let value = fv["Observation Identifier"].replace(/^([\s]*)\s/, '').split(' ');
                                return (
                                <div className="folgen-pill" key={`edition-folgen-pill-${i}`}>
                                    {`${value[1]} ${value[2]}`}
                                </div>
                                )
                            }) : loadingAiReview ? (<p>Loading...</p>) : (<p>{tMedicalReview("all-folgen-values-processed")}</p>)}
                        </div>
                    </div>

                    
                </div>
            </Col>
        </Row>

        { /* Survey section */}
        {!loadingAiReview && canVote ? (
            <Row className="ki-report">
                <CardBody>
                    {/* Preguntas de evaluación */}
                        <div className="editor-bottom">
                            <div className='divSurvCont' testId="survey_section">
                            
                                <Col md={5}>
                                    <Label className="survTitle">{tEditor("survey-comment-complete")}</Label>

                                    <Col md={12} className="text-left cont-surv-radio">
                                        <Label className="mr-4 rad-surv">
                                            <Input type="radio" testId="survey_completely_1" name="survey_completely" value="YES" disabled={!canVote} />{tEditor("yes")}
                                        </Label>
                                        <Label className="mr-4 rad-surv">
                                            <Input type="radio" testId="survey_completely_2" name="survey_completely" value="NO" disabled={!canVote} />{tEditor("no")}
                                        </Label>
                                        <Label className="rad-surv" >
                                            <Input type="radio" testId="survey_completely_3" name="survey_completely" value="I_DONT_KNOW" disabled={!canVote} />{tEditor("dont-know")}
                                        </Label>
                                    </Col>
                                </Col>

                                <Col md={5}>
                                    <Label className="survTitle">{tEditor("survey-comment-correct")}</Label>

                                    <Col md={12} className="text-left  cont-surv-radio">
                                        <Label className="mr-4 rad-surv">
                                            <Input type="radio" testId="survey_right_1" name="survey_right" value="YES" disabled={!canVote} />{tEditor("yes")}
                                        </Label>
                                        <Label className="mr-4 rad-surv">
                                            <Input type="radio" testId="survey_right_2" name="survey_right" value="NO" disabled={!canVote} />{tEditor("no")}
                                        </Label>
                                        <Label className="rad-surv">
                                            <Input type="radio" testId="survey_right_3" name="survey_right" value="I_DONT_KNOW" disabled={!canVote} />{tEditor("dont-know")}
                                        </Label>
                                    </Col>
                                </Col>

                                <Col md={1}>
                                    <Button
                                        onClick={(e) => {
                                            e.preventDefault();
                                            submitSurvey();
                                        }}
                                        testId="submit_vote_button"
                                        className="btn btn-success"
                                    >
                                        {tEditor("vote")}
                                    </Button>
                                </Col>
                            </div>
                        </div>
                </CardBody>
            </Row>
        ) : !loadingAiReview ? (
            <Row>
                <ReactTooltip
                    id={`save-button-disabled`}
                    aria-haspopup="true"
                    effect="float"
                    type="dark"
                    place="bottom"
                    className={study.review_delivery_enabled ? "d-none" : ""}
                >
                    {tMedicalReview('notice-to-modify-commentar')}
                </ReactTooltip>

                <Col lg={"12"}>
                    <div className="review-field">
                        <div className="d-flex justify-content-between" style={{width: "100%"}}>
                            <div>
                                <span style={{display: "inline-block"}} data-tip data-for={`save-button-disabled`}>
                                    <Button
                                        color="info"
                                        testId="review-save"
                                        disabled={!study.review_delivery_enabled}
                                        onClick={(e) => {
                                            if (review !== "<p><br></p>") {
                                                e.preventDefault();
                                                setCanSend(true);
                                                setEdited(false);
                                                updateReview(review, true);
                                                showTemporalSavedIcon();
                                            }
                                        }}
                                    >
                                        {tMedicalReview('btn-save')}
                                    </Button>
                                </span>

                                <Button
                                    color="success"
                                    testId="review-send-bill"
                                    size="md"
                                    disabled={!canSend || edited}
                                    onClick={(e) => {
                                        e.target.blur();
                                        e.preventDefault();
                                        sendReview(true);
                                    }}
                                >
                                    {tMedicalReview('btn-send-to-patient')}
                                </Button>

                                <Button
                                    className="btn-no-bill"
                                    testId="review-send-no-bill"
                                    size="md"
                                    disabled={!canSend || edited}
                                    onClick={(e) => {
                                        e.target.blur();
                                        e.preventDefault();
                                        sendReview();
                                    }}
                                >
                                    {tMedicalReview('btn-send-to-patient-no-billing')}
                                </Button>
                            </div>

                            <Button
                                color="info"
                                testId="review-draft"
                                style={{ maxHeight: "38.2px" }}
                                onClick={(e) => {
                                    if (review !== "<p><br></p>") {
                                        e.preventDefault();
                                        setEdited(false);
                                        updateReview(review, true, true);
                                        showTemporalSavedIcon();
                                    }
                                }}
                            >
                                {tMedicalReview('btn-only-save')}
                            </Button>
                        </div>

                        {/* Replicate folgenValues layout width */}
                        <div className="pseudo-folgen-values-section"></div>
                    </div>

                </Col>
            </Row>
        ) : (<></>)}

    </>
    )
}

export default React.memo(DoctorReviewField);