import React, { useState, useEffect, useRef } from 'react';
import { Table, Input, Button, Row, Col, Label } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';

// Include Translation tool
import { useTranslate } from "react-translate";

// Include a particular scss for this view
import 'assets/scss/custom/_kilab_list.scss';
import { useSelector, useDispatch } from 'react-redux';
import { actionSetContext, actionSendContext, actionGenerateAIComment } from 'store/actions/kiLabActions';
import { actionSweetAlert } from 'store/actions/commonActions';
import LoadingLocal from 'plugins/TS-lib-utils-public/components/Loading/LoadingLocal';
import { actionSetAIGenerationError } from 'store/actions/kiLabActions';

const KiLabEditor_data = ({setActiveTab, setMasterEdited, setCanSend}) => {

    const dispatch = useDispatch();
    const tEditor = useTranslate('kilab-editor');
    const t = useTranslate("kilab");
    const study = useSelector(state => state.kiLabReducer.study);
    const aiGenError = useSelector(state => state.kiLabReducer.aiGenerationError);
    const isLoadingAI = useSelector((state) => state.kiLabReducer.loadingAiReview);
    const context = useSelector(state => state.kiLabReducer.context);
    const loaded = useRef(false);

    const [bornYear, setBornYear] = useState(Object.keys(context).length ? context.birthYear : Object.keys(study).length ? study.metadata.patient_data.birth_year : "");
    const [gender, setGender] = useState(Object.keys(context).length ? context.gender : Object.keys(study).length ? study.metadata.patient_data.gender : "");
    const [hormonYear, setHormonYear] = useState(context.hormoneYear);
    const [hormonCycle, setHormonCycle] = useState(context.hormoneCycle ? context.hormoneCycle : "");
    const [hormonMonth, setHormonMonth] = useState(context.hormoneMonth ? context.hormoneMonth : "");
    const [hormonStatus, setHormonStatus] = useState(context.hormoneStatus);
    const [additional, setAdditional] = useState(context.additionalInfo);
    const [edited, setEdited] = useState(false);
    const [generated, setGenerated] = useState(false);

    //Effect for general record saving
    useEffect(() => {
        if (Object.keys(study).length && loaded.current) {
            dispatch(actionSetContext({
                gender: gender,
                birthYear: bornYear,
                hormoneYear: hormonYear,
                hormoneStatus: hormonStatus,
                hormoneCycle: hormonCycle ? hormonCycle : undefined,
                hormoneMonth: hormonMonth ? hormonMonth : undefined,
                additionalInfo: additional
            }));

            dispatch(actionSendContext(study._id, {
                gender: gender,
                birthYear: bornYear,
                hormoneYear: hormonYear,
                hormoneStatus: hormonStatus,
                hormoneCycle: hormonCycle ? hormonCycle : undefined,
                hormoneMonth: hormonMonth ? hormonMonth : undefined,
                additionalInfo: additional
            }))
        }
    }, [bornYear, gender, hormonYear, hormonStatus, additional, hormonMonth, hormonCycle]);

    //Effect for edition
    useEffect(() => {
        if (Object.keys(study).length && loaded.current) {
            //Check if the hook for the current selected value is different than default values
            const val = hormonStatus === "pregnancy" 
            ? hormonMonth 
            : hormonStatus === "cycle" 
            ? hormonCycle 
            : hormonYear;

            if (val !== "") {
                setEdited(true);
                setMasterEdited(true);
            }
        }
    }, [bornYear, gender, hormonYear, additional, hormonMonth, hormonCycle]);

    //Set context locally on comment edition
    useEffect(() => {
        dispatch(actionSetContext({
            gender: gender,
            birthYear: bornYear,
            hormoneYear: hormonYear,
            hormoneStatus: hormonStatus,
            hormoneCycle: hormonCycle ? hormonCycle : undefined,
            hormoneMonth: hormonMonth ? hormonMonth : undefined,
            additionalInfo: additional
        }));
    }, [edited]);

    useEffect(() => {
        loaded.current = true;
    }, [context]);

    //Effect in case of AI generation error
    useEffect(() => {
        if (aiGenError) {
            dispatch(
                  actionSweetAlert(
                    <SweetAlert
                      custom
                      customIcon="/General/icons/warning-sign-orange.svg"
                      title={t('ai-generation-error-title')}
                      onConfirm={() => {}}
                      customButtons={
                        <>
                          {/*Custom buttons for more control*/}
                          <Button
                            className="mb-3"
                            color="info"
                            size="lg"
                            onClick={() => {
                              dispatch(actionSweetAlert(null));
                              dispatch(actionSetAIGenerationError(false));
                            }}
                          >
                            {t('btn-ok')}
                          </Button>
                        </>
                      }
                    >
                        {t("ai-generation-error")}
                    </SweetAlert>
                  )
                );
        }
    }, [aiGenError])
    
    

    //Hardcoded values
    const femaleHormonStatus = [
        { state:"Zyklustag", value: "cycle"},
        { state:"Schwangerschaft", value: "pregnancy"},
        { state:"Menarche", value: "menarche"},
        { state:"Prämenopause", value: "premenopause"},
        { state:"Perimenopause", value: "perimenopause"},
        { state:"Menopause", value: "Menopause"},
        { state:"Postmenopause", value: "Postmenopause"},
    ];

    const maleHormonStatus = [
        { state:"Vorpubertät", value: "prepuberty"},
        { state:"Postpubertät", value: "post-puberty"},
        { state:"Andropause", value: "andropause"},
    ];

    const handleGenerateClick = () => {
        setGenerated(true);
        setActiveTab('tab-comment');
        dispatch(actionGenerateAIComment(study._id));
        setEdited(false);
        setMasterEdited(true);
        setCanSend(false);
    }
    
    // Genera arrays de años, dias y meses
    const years = [];
    const cycles = [];
    const months = [];
    const currentYear = new Date().getFullYear();

    for (let year = currentYear; year >= 1920; year--) {
        years.push(String(year));
    }

    for (let i = 1; i <= 99; i++) {
        cycles.push(String(i));
    }

    for (let i = 1; i <= 11; i++) {
        months.push(String(i));
    }

    if (!Object.keys(study).length || !study.metadata) {
        return <></>;
    }
 
    return (
            <Row>
                {/* Left side - Details in a table with custom border style */}
                <Col md={6}>
                    <Table borderless className="details-table">
                        <tbody>
                            <tr className="table-row">
                                <td><Label className="title">ID</Label></td>
                                <td><Label className="title">{tEditor("receipt-date")}</Label></td>
                                <td><Label className="title">{tEditor("born-date")}</Label></td>
                            </tr>
                            <tr className="table-row-space">
                                <td><p testId="study_id">{study.metadata.analysis_id.ext_ref}</p></td>
                                <td><p>22-08-2024</p></td>
                                <td>
                                    <Input type="select" disabled={isLoadingAI} name="bornYear" testId="born_year" onChange={e => {setBornYear(e.target.value)}}>
                                        <option key="" value="">{tEditor("choose-year")}</option>
                                        {years.map((year) => (
                                            <option key={year} value={year} selected={bornYear === year}>
                                                {year}
                                            </option>
                                        ))}
                                    </Input>
                                </td>
                            </tr>
                            <tr className="table-row">
                                <td><Label className="title">{tEditor("gender")}</Label></td>
                                <td><Label className="title">{tEditor("hormone-status")}</Label></td>
                                <td><Label className="title">{tEditor("statement")}</Label></td>
                            </tr>
                            <tr className="table-row-space">
                                <td>
                                    <Input type="select" disabled={isLoadingAI} testId="gender_input" onChange={e => {
                                            setHormonStatus("");
                                            setHormonYear("");
                                            setHormonMonth("");
                                            setHormonCycle("");
                                            setGender(e.target.value);
                                        }}>
                                        <option value="">Geschlecht</option>
                                        <option value="Male" testId="male_option" selected={gender === "Male"}>{tEditor("Male")}</option>
                                        <option value="Female" testId="female_option" selected={gender === "Female"}>{tEditor("Female")}</option>
                                    </Input>
                                </td>
                                <td>
                                    <Input type="select" disabled={isLoadingAI} testId="hormonstatus_input" onChange={e => {
                                            setHormonYear("");
                                            setHormonMonth("");
                                            setHormonCycle("");
                                            setHormonStatus(e.target.value)
                                        }}>
                                        <option value="">-</option>
                                        {gender === "Female" ? femaleHormonStatus.map((o, i) => (
                                            <option 
                                                value={o.value} 
                                                testId={o.value}
                                                key={`female-hormon-${i+1}`} 
                                                selected={hormonStatus === o.value}
                                            >
                                                {o.state}
                                            </option>
                                        )) : gender === "Male" ? maleHormonStatus.map((o,i) => (
                                            <option 
                                                value={o.value} 
                                                testId={o.value}
                                                key={`male-hormon-${i+1}`} 
                                                selected={hormonStatus === o.value}>
                                                {o.state}
                                            </option>
                                        )) : (<></>)}
                                    </Input>
                                </td>
                                <td>

                                    {hormonStatus === "cycle" ? (
                                        <Input type="select" disabled={isLoadingAI} name="hormonCycle" testId="hormon_cycle_input" onChange={e => {
                                                setHormonYear("");
                                                setHormonMonth("");
                                                setHormonCycle(e.target.value);
                                            }}>
                                            <option key="" value="">{tEditor("day")}</option>
                                            {cycles.map((cycle) => (
                                                <option key={`cycle-${cycle}`} value={cycle} testId={`hormon_cycle_${cycle}`} selected={hormonCycle === cycle}>{cycle}</option>
                                            ))}
                                        </Input>
                                    ) : hormonStatus === "pregnancy" ? (
                                        
                                        <Input type="select" disabled={isLoadingAI} name="hormonMonth" testId="hormon_month_input" onChange={e => {
                                            setHormonMonth(e.target.value);
                                            setHormonYear("");
                                            setHormonCycle("");
                                        }}>
                                            <option key="" value="">{tEditor("month")}</option>
                                            {months.map((month) => (
                                                <option key={`month-${month}`} value={month} testId={`hormon_month_${month}`} selected={hormonMonth === month}>{month}</option>
                                            ))}
                                        </Input>
                                        ) : (
                                        <Input type="select" disabled={isLoadingAI || (!hormonStatus || !hormonStatus.length)} testId="hormon_year_input" name="hormonYear" onChange={e => {
                                            setHormonYear(e.target.value);
                                            setHormonMonth("");
                                            setHormonCycle("");
                                        }}>
                                            <option key="" value="">{(!hormonStatus || !hormonStatus.length) ? "-" : tEditor("choose-year")}</option>
                                            {years.map((year) => (
                                                <option key={`year-${year}`} value={year} testId={`hormon_year_${year}`} selected={hormonYear === year}>{year}</option>
                                            ))}
                                        </Input>
                                    )}


                                </td>
                            </tr>
                            <tr className="table-row">
                                <td><Label className="title">{tEditor("practice")}</Label></td>
                                <td><Label className="title">{tEditor("analysis-status")}</Label></td>
                                <td><Label className="title">{tEditor("status")}</Label></td>
                            </tr>
                            <tr className="table-row-space">
                                <td>
                                    <p>Praxis Sender</p>
                                </td>
                                <td>
                                    <p>Teilbefund</p>
                                </td>
                                <td>
                                    <p>In Bearbeitung</p>
                                </td>
                            </tr>

                        </tbody>
                    </Table>
                </Col>

                {/* Right side - Additional Information */}
                <Col md={6} className="freeTextContainer" >
                    <Table borderless className="details-table">
                    <tbody>
                        <tr>
                            <td className='table-row'>
                                <div>
                                    <p className="freeTextTitle">
                                        <b>{tEditor("extra-info")}</b>
                                    </p>
                                </div>
                                <Input
                                    type="textarea"
                                    name="freeTextInput"
                                    testId="additional_info_input"
                                    value={additional}
                                    disabled={isLoadingAI}
                                    onChange={e => {setAdditional(e.target.value)}}
                                    placeholder={`Freitext hier eingeben, z.B.:\n- Relevante Medikamente\n- Begleiterkrankungen\n- Körpergröße\n- Gewicht`}
                                />

                                <div className="d-flex" >
                                    <Button 
                                        className="mt-3 btn-success" 
                                        testId="ai_generate"
                                        disabled={isLoadingAI || (!edited && generated)} 
                                        onClick={handleGenerateClick}
                                    >
                                        {tEditor('btn-ai-analyze')}
                                    </Button>

                                    {isLoadingAI && (
                                        <div className="kilab-ai-loading" testId="aigen_loading_indicator">
                                            <LoadingLocal/>
                                        </div>
                                    )}
                                </div>
                                

                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
    );
}
export default KiLabEditor_data;