import React, { useState, useEffect } from "react";
import {
    Card,    CardBody, CardHeader, CardTitle,  Button,
    Input,   Label,    Row,        Col,        Nav,
    NavItem, NavLink,  TabContent, TabPane,    Form
} from "reactstrap";
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { actionGetEarlyStudy, actionSetFromDetails } from 'store/actions/kiLabActions';

// Include params library
import { useParams, useHistory } from 'react-router';

// Include Translation tool
import { useTranslate } from "react-translate";

// Include the components
import KiLabEditor_data from "components/KiLab/KiLabEditor_data";
import KiLabEditor_analytes from "components/KiLab/KiLabEditor_analytes";
import KiLabEditor_comment from "components/KiLab/KiLabEditor_comment";

// Include a particular scss for this view
import 'assets/scss/custom/_kilab_editor.scss';
import LoadingLocal from "plugins/TS-lib-utils-public/components/Loading/LoadingLocal";
import { actionRemoveDoctorFromEarlyStudy } from "store/actions/kiLabActions";



const KiLabEditor = () => {

    // Hook to redirect
    const history = useHistory();
    const dispatch = useDispatch();
    const practiceId = useSelector(state => state.practicesReducer.practice._id);
    const isLoading = useSelector(state => state.kiLabReducer.loadingStudy);
    const study = useSelector(state => state.kiLabReducer.study);
    const user = useSelector(state => state.userReducer);
    const [edited, setEdited] = useState(false);
    const [canSend, _setCanSend] = useState(false);
    const [review, setReview] = useState(
            study && study.draft
            ? study.draft.text
            : ""
        );

    const setCanSend = (val) => {
        if (((review && review.length) || (study && study.draft))) {
            _setCanSend(val);
        }
    }
    
    // Get AnalysisId from URL
    const { id_lab_result } = useParams("id_lab_result");

    useEffect(() => {
        dispatch(actionGetEarlyStudy(id_lab_result, practiceId, true));

        return () => {
            dispatch(actionRemoveDoctorFromEarlyStudy(user.auth_user_id));
        }
    }, []);

/*
    useEffect(()  => {
        // TO-DO : Comment this kind of logics, to understand the purpose of the conditional
        if (study && Object.keys(study).length && study.assignedDoctors) {
            alert("Redirige 1")


            const found = study.assignedDoctors.find(d => d.auth_user_id === user.auth_user_id);


            // TO-DO : Aparentemente en algunas situaciones no matchea el usuaior del state con el usuario del estiudio
            console.log("-------")

            //console.log( "d.auth_user_id: " + d.auth_user_id  )
            console.log( "user.auth_user_id: " + user.auth_user_id )
            
            if (!found) {
                alert("Redirige 2")
                history.push("/admin/kilab/index");
            }
        }
    }, [study]);
    */
    
    // Fuerzo al hoock a trabajar ne ascincronico para garantizar que la comparación se haga de forma correcta
    useEffect(() => {
        if (!isLoading && study && Object.keys(study).length && study.assignedDoctors) {
            // Buscar si el usuario está en la lista de assignedDoctors
            const found = study.assignedDoctors.find(d => d.auth_user_id === user.auth_user_id);

            /*
            console.log("-------");
            console.log("user.auth_user_id: " + user.auth_user_id);
            */

            //alert("d 1")
            if (!found) {
                // Si no lo encontró, redirigir
                //alert("d 2")
                history.push("/admin/kilab/index");
            }
        }

    }, [study, user, isLoading]);  // Asegúrate de que todos los valores necesarios están en las dependencias



    // Invoke translation lib
    const t = useTranslate('kilab');
    const tEditor = useTranslate('kilab-editor');


    // State to manage active tab
    const [activeTab, setActiveTab] = useState('tab-result');

    const toggle = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    const handleBackClick = () => {
        dispatch(actionSetFromDetails(true));
        history.goBack();
    }

    return (
        <Card className="full-height">
            <CardBody>
                <Card name="body" className="expand-height">
                    <CardHeader>
                        <CardTitle className="d-flex align-content-center">
                            <div className="general-card-title-img text-primary mr-2">
                                <i className="icon-TP_KI_Logo_White" />
                            </div>
                            <div className="general-card-title text-primary">{t('title-view')}</div>
                        </CardTitle>
                        <Button className="btn btn-info btn-md" onClick={handleBackClick} testId="kilab_back">Zurück</Button>
                    </CardHeader>

                    <CardBody className="card-min-height">

                        {isLoading ? <LoadingLocal/> : (
                            <Form>

                                { /*************************************
                                Invoke the component to show the parameters for the AI question */ }
                                <KiLabEditor_data 
                                    setActiveTab={ setActiveTab }
                                    masterEdited={edited}
                                    setMasterEdited={setEdited}
                                    setCanSend={setCanSend}
                                />

                                <Nav pills className="nav-pills-primary mb-4 mt-4">
                                    <NavItem className="profile-item" testId="analytes_section">
                                        <NavLink
                                            className={classnames({ active: activeTab === 'tab-result' })}
                                            onClick={() => { toggle('tab-result'); }}>
                                                {tEditor('tab-detail')}
                                        </NavLink>
                                    </NavItem>                                
                                    <NavItem className="profile-item" testId="comment_section">
                                        <NavLink
                                            className={classnames({ active: activeTab === 'tab-comment' })}
                                            onClick={() => { toggle('tab-comment'); }}>
                                                {tEditor('tab-doctor-review')}
                                        </NavLink>
                                    </NavItem>
                                </Nav>


                                <TabContent activeTab={activeTab} className="mt-4">
                                    
                                    { /*********************************
                                    Component to render de analytes tab */ }
                                    <KiLabEditor_analytes />


                                    { /*********************************
                                    Component to render de comment tab */ }
                                    <KiLabEditor_comment 
                                        masterEdited={edited} 
                                        setMasterEdited={setEdited}
                                        canSend={canSend}
                                        setCanSend={setCanSend}
                                        review={review}
                                        setReview={setReview}
                                    />
                                
                                    
                                </TabContent>
                            </Form>
                        )}
                        
                    </CardBody>
                </Card>                
            </CardBody>
        </Card>
    );
};

export default KiLabEditor;