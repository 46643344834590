import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionGetHelps } from 'store/actions/helpsActions';
import routes from 'routes';

//for now we save the helpRoutes in json file,this json we help to match with 'Help' each page 
import helpRoutes from 'plugins/TS-lib-utils-public/data/helpInfoWebDoctors.json';

const useHelpKey = (activeRoute) => {
  
  //get Helps wich are saved since web-management
  const { keys } = useSelector((state) => state.helpsReducer);

  const { language } = useSelector((state) => state.userReducer);

  const dispatch = useDispatch();

  const [activeKey, setActiveKey] = useState(false);

  const getRouteKey = useCallback(() => {
    //check if the route selected since Sidebar  is equal wiht some routes saved
    const route = routes.find((r) => activeRoute.slice(6).includes(r.path.replace(/(:\S+)\?/, '')));
    if (!route) {
      return null;
    }
    const name = route.name.toLowerCase();

    if (!name) {
      return null;
    }
    //Obtain key current 'Help'
    let key = helpRoutes.routes.find((help) => help._id.toLowerCase() === name);

    if (!key) {
      //If key didn't match by name, match by id
      key = helpRoutes.routes.find((help) => Number(help.key) === route._id); 
    }
    
    if (key) {
      key = key.key;
    } else {
      key = route.path.slice(1);
    }

    return key;
  }, [activeRoute]);

  useEffect(() => {
    dispatch(actionGetHelps());
  }, [dispatch]);

  useEffect(() => {
    const currentKey = getRouteKey();
    const routeKey = currentKey === null ? '' : currentKey.toString();
    const activeKeyData = keys.find((key) => key.key === routeKey);
    if (activeKeyData) {
      let newKeyData = {
        key: activeKeyData.key,
        title: activeKeyData.title,
      };

      const newData = activeKeyData.text.find(
        (text) => text.language === language
      );

      newKeyData = { ...newKeyData, ...newData };

      setActiveKey(newKeyData);
    }else{
      let newKeyData = {
        key: ""
      };
      setActiveKey(newKeyData);

    }
    // eslint-disable-next-line
  }, [keys, language, activeRoute]);

  return activeKey;
};

export default useHelpKey;
