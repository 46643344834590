import { takeEvery, put, call, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
//Redux Actions
import CONSTANTS from '../CONSTANTS';
import {
  actionGetInvitationList,
  actionSetInvitationList,
  actionSetUsersList,
} from '../actions/userDataActions';
//Services
import UsersService from '../../services/UsersService.js';
import { actionRefreshPermissions } from 'store/actions/usersActions';
import { getUser } from 'store/selectors/selectors';
import { getInvitations } from 'store/selectors/selectors';

/**
 * Get a invitation list
 */
function* sagaGetInvitations() {
  try {
    const user = yield select(getUser);
    const result = yield call(UsersService.getInvitations, user._id);
    if (result.status >= 400) {
      throw new Error('Get invitations failed');
    }
    yield put(actionSetInvitationList(result.data));
  } catch (error) {
    console.log(error);
    toast.error('Get invitations failed');
  }
}

/**
 * Resolve a invitation
 */
function* sagaResolveInvitation({ practice_invitation_id, status }) {
  try {
    const invitations = yield select(getInvitations);
    const invitation = invitations.find(i => i.practice_invitation_id === practice_invitation_id);
    const result = yield call(
      UsersService.resolveInvitation,
      practice_invitation_id,
      status
    );
    
    yield put(actionGetInvitationList());

    if (result.status >= 400) {
      throw new Error('Resolve invitation failed');
    }
    if (invitation && status === 'accepted') {
      yield put(actionRefreshPermissions(invitation.practice_id));
    }
    toast.success(`Invitation ${status}`);
  } catch (error) {
    console.log(error);
    toast.error('Resolve invitation failed');
  }
}
/**
 * Get a user list for send messages
 */
function* sagaGetUsers({ query }) {
  try {
    const result = yield call(UsersService.getUsers, query);
    if (result.status >= 400) {
      throw new Error('Get users failed');
    }
    yield put(actionSetUsersList(result.data));
  } catch (error) {
    console.log(error);
    toast.error('Get invitations failed');
  }
}

export function* userDataSaga() {
  console.log('*Main Users Data Saga');
  yield takeEvery(CONSTANTS.INVITATION_GET_LIST, sagaGetInvitations);
  yield takeEvery(CONSTANTS.INVITATION_RESOLVE, sagaResolveInvitation);
  yield takeEvery(CONSTANTS.USERS_GET_LIST, sagaGetUsers);
}
