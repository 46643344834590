import CONSTANTS from '../CONSTANTS';

export const actionGetEarlyStudies = (options) => ({

  //Tell to redux that must execut an action of this type
  type: CONSTANTS.LAB_EARLY_GET_LIST,
  options
});

export const actionSetEarlyStudies = (studies) => ({
  type: CONSTANTS.LAB_EARLY_SET_LIST,
  studies
});

export const actionGetEarlyStudy = (reportId, practiceId, registerDoctor = false) => ({
  //registerDoctor to control assignedDoctors list
  type: CONSTANTS.LAB_EARLY_GET_STUDY,
  reportId, practiceId, registerDoctor
});

export const actionSetEarlyStudy = (study) => ({
  type: CONSTANTS.LAB_EARLY_SET_STUDY,
  study
});

export const actionSetSearchQueryEarly = (query) => ({
  type: CONSTANTS.LAB_EARLY_SET_QUERY,
  query
})

export const actionSetFromDetails = (val) => ({
  type: CONSTANTS.LAB_EARLY_COMING_FROM_DETAILS,
  val
});

export const actionSendEarlyReview = (data) => ({
  type: CONSTANTS.LAB_EARLY_SAVE_REVIEW,
  data
});

export const actionSaveEarlyDraft = (data) => ({
  type: CONSTANTS.LAB_EARLY_SAVE_DRAFT,
  data
});

export const actionSetContext = (context) => ({
  type: CONSTANTS.LAB_EARLY_SET_CONTEXT,
  context
});

export const actionSendContext = (studyId, context) => ({
  type: CONSTANTS.LAB_EARLY_SAVE_CONTEXT,
  studyId,
  context
});

export const actionGenerateAIComment = (studyId) => ({
  type: CONSTANTS.LAB_EARLY_GENERATE_AI_COMMENT,
  studyId
});

export const actionSetLoadingEarlyStudy = (val) => ({
  type: CONSTANTS.LAB_EARLY_SET_LOADING_STUDY,
  val
});

export const actionSetLoadingAIReview = (val) => ({
  type: CONSTANTS.LAB_EARLY_SET_LOADING_AI,
  val
});

export const actionUpdateEarlyStudyFromList = (studyId, doctor) => ({
  type: CONSTANTS.LAB_EARLY_UPDATE_LOCAL_STUDY,
  studyId,
  doctor
});

export const actionRemoveDoctorFromEarlyStudy = (auth_user_id) => ({
  type: CONSTANTS.LAB_EARLY_REMOVE_DOCTOR_FROM_STUDY,
  auth_user_id
});

export const actionSetAIGenerationError = (val) => ({
  type: CONSTANTS.LAB_EARLY_SET_AI_GEN_ERROR,
  val
});

export const actionSubmitKilabSurvey = (practiceId, option1, option2) => ({
  type: CONSTANTS.LAB_EARLY_SUBMIT_SURVEY,
  practiceId,
  option1,
  option2
});
