import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actionSetLang } from 'store/actions/usersActions';
import languages from 'configs/lang';

import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from 'reactstrap';
import Cookies from 'helpers/Cookies';
import { actionUpdateLang } from 'store/actions/usersActions';

function LanguageSwitch() {
  const language = useSelector((state) => state.userReducer.language);
  const user = useSelector((state) => state.userReducer);

  const [dropdownOpenLanguage, setdropdownOpenLanguage] = useState(false);

  const dispatch = useDispatch();

  const handleLang = (lang) => {
    Cookies.setCookie('tp-lang', lang, 1);
    if (!user._id) {
      dispatch(actionSetLang(lang));
    } else {
      dispatch(actionUpdateLang(lang));
    }
  };

  const dropdownToggleLanguage = (e) => {
    setdropdownOpenLanguage(!dropdownOpenLanguage);
  };


  return (
    <Dropdown
      nav
      isOpen={dropdownOpenLanguage}
      toggle={dropdownToggleLanguage}
      className="d-none d-md-block"
    >
      <DropdownToggle caret nav>
        <i className="d-lg-none d-md-inline-block location_world" />
        <p className="ml-2 p_cont_lang"> 
          <span className="d-lg-block">{languages[language || 'de'].abbr}</span>
        </p>
      </DropdownToggle>
      <DropdownMenu right>
        {Object.entries(languages).map(([key, value]) => {
          
          if (value.abbr !== "DEU") {
            return null;
          }
          
          return (
            <DropdownItem
              tag="a"
              href="#"
              data-abbr={value.abbr}
              key={key}
              id={key}
              onClick={(e) => {
                e.preventDefault();
                handleLang(key);
              }}
            >
              {value.name}
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </Dropdown>
  );
}

export default LanguageSwitch;