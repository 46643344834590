import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Col,
    Row,
} from "reactstrap";
import { useTranslate } from "react-translate";
import { actionSweetAlert } from 'store/actions/commonActions';
import SweetAlert from 'react-bootstrap-sweetalert';
import { 
    actionDeleteDraft,
} from "store/actions/laboratoryActions";
import Icon from "components/Icons/Icon";
import { toast } from "react-toastify";
import { sanitizeHtmlReview } from "helpers/misc";

const DoctorReviewHistory = ({ study, reportId }) => {
    const user = useSelector(state => state.userReducer);
    const tMedicalReview = useTranslate('medical-review');
    const dispatch = useDispatch();
    const { practice } = useSelector(state => state.practicesReducer);

    return (
    <>
    {study.reviews.map((r, i) => {
        if (r.sent_at || r.is_draft) {
            return (
                <div className="review-card" testId={`review-${i}`} key={`review_${i}`}>
                    <Row>
                            {r.is_draft ? (<span testId={`draft-icon-${i}`} className="draft-icon"><Icon file="draft_.svg" /></span>) : <div style={{width: "50px"}}></div>}
                            <div className="review-left-header" style={{marginBottom: "15px"}}>

                                <div style={{display:"block"}}>
                                    <div className="d-flex">
                                        <div className="review-date">{moment(r.updated_at ? r.updated_at : r.created_at).format(`${user.dateFormat} HH:mm`)}</div>
                                            
                                        <span className={`review-ki-edited`}>
                                            Arzt-Befund
                                        </span>
                                    </div>

                                    <div>
                                        {study.patient.firstName} {study.patient.lastName} *{moment(study.patient.birthday, "YYYYMMDD").format(user.dateFormat)}
                                    </div>
                                </div>

                                

                                {!r.is_draft && (
                                    <div className="review-icon-section">
                                        <span className={r.isBilling ? "billing-green" : "billing-orange"}>
                                            {r.isBilling ? tMedicalReview("billed") : tMedicalReview("not-billed")}
                                        </span>
                                        <Icon className="euro-icon" file={`euro-currency-symbol_${r.isBilling ? "green" : "rot_crossed_out"}.svg`} width="20px" height="20px"/>
                                    </div>
                                )}

                            </div>
                        <Col sm={12} md={9} lg={9}>
                            <div className="review-content" testId={`review-content-${i}`} dangerouslySetInnerHTML={{__html: r.content}}></div>
                        </Col>

                        {r.folgenValues && r.folgenValues.length ? (
                            <Col sm={12} md={3} lg={3} className="d-none d-md-block">
                                <div className="review-left-header">
                                    <div>
                                        <p>{tMedicalReview("folgen-title")}</p>
                                    </div>
                                </div>
                                <div className="folgen-section">
                                    {r.folgenValues.map((fv,i) => {
                                        let value = fv["Observation Identifier"].replace(/^([\s]*)\s/, '').split(' ');
                                        return (
                                        <div className="folgen-pill" key={`folgen-pill-${i}`}>
                                            {`${value[1]} ${value[2]}`}
                                        </div>
                                        )
                                    })}
                                </div>
                            </Col>
                        ) : (
                            <Col sm={12} md={3} lg={3} className="d-none d-md-block">
                                <div className="review-left-header">
                                    <p>{tMedicalReview("folgen-title")}</p>
                                </div>
                                <div className="folgen-section">
                                    <p>{tMedicalReview("all-folgen-values-processed")}</p>
                                </div>
                            </Col>
                        )}
                    </Row>


                    {r.is_draft && (
                        <div className="d-flex align-items-end flex-column">
                            <div className="d-flex align-items-end">

                                <span
                                    className="mr-3 pointer"
                                    testId={`draft-copy-btn-${i}`}
                                    onClick={(e) => {
                                        navigator.clipboard.writeText(sanitizeHtmlReview(r.content));
                                        toast.success("Text in die Zwischenablage kopiert");
                                    }}
                                > 
                                    <Icon file="copy_.svg" width="25px"/> 
                                </span>
                                
                                <span
                                    className="pointer"
                                    testId={`draft-delete-btn-${i}`}
                                    onClick={e => {
                                        dispatch(
                                            actionSweetAlert(
                                              <SweetAlert
                                                custom
                                                customIcon="/General/icons/warning-sign-orange.svg"
                                                title={tMedicalReview('are-you-sure')}
                                                onConfirm={() => {}}
                                                customButtons={
                                                  <>
                                                    {/*Custom buttons for more control*/}
                                                    <Button
                                                      className="mb-3"
                                                      color="info"
                                                      testId="alert-draft-delete-ok"
                                                      size="lg"
                                                      onClick={() => {
                                                        dispatch(actionSweetAlert(null));
                                                        dispatch(actionDeleteDraft(reportId, r._id, study.doctor_id, practice._id));
                                                      }}
                                                    >
                                                      {tMedicalReview('btn-ok')}
                                                    </Button>
                                      
                                                    <Button
                                                      className="mb-3 ml-2"
                                                      color="danger"
                                                      testId="alert-draft-delete-cancel"
                                                      size="lg"
                                                      onClick={() => dispatch(actionSweetAlert(null))}
                                                    >
                                                      {tMedicalReview('btn-cancel')}
                                                    </Button>
                                                  </>
                                                }
                                              >
                                                <div testId={`alert-draft-delete-content`}>{tMedicalReview("delete-draft-text")}</div>
                                              </SweetAlert>
                                            )
                                          );
                                    }}
                                > 
                                    <Icon file="delete_.svg" width="25px"/> 
                                </span>
                            </div>
                        </div>
                    )}
                    
                </div>
            )
        }
    })}
    </>
    )
}

export default React.memo(DoctorReviewHistory);