import React, { useState } from 'react';
import { Table, Badge, Row, Col, CardImg, Collapse, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import moment from "moment";

// Include Translation tool
import { useTranslate } from "react-translate";

//Import the Icons
import Icon from "components/Icons/Icon";
import { useSelector } from 'react-redux';

const ReviewItem = ({ result, index }) => {
    // Translation library
    const tEditorStored = useTranslate('kilab-editor-stored-list');
    const tEditor = useTranslate('kilab-editor');
    const tMedicalReview = useTranslate('medical-review');

    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => {
        setIsOpen(!isOpen);
    }

    const femaleHormonStatus = [
        { state:"Schwangerschaft (Monat 1-3)", value: "pregnancy (months 1-3)"},
        { state:"Schwangerschaft (Monat 4-6)", value: "pregnancy (months 4-6)"},
        { state:"Schwangerschaft (Monat 7-9)", value: "pregnancy (months 7-9)"},
        { state:"Schwangerschaft", value: "pregnancy"},
        { state:"Zyklustag", value: "cycle"},
        { state:"Menarche", value: "menarche"},
        { state:"Prämenopause", value: "premenopause"},
        { state:"Perimenopause", value: "perimenopause"},
        { state:"Menopause", value: "Menopause"},
        { state:"Postmenopause", value: "Postmenopause"},
    ];

    const maleHormonStatus = [
        { state:"Vorpubertät", value: "prepuberty"},
        { state:"Postpubertät", value: "post-puberty"},
        { state:"Andropause", value: "andropause"},
    ];

    return (
        <div className="review-card-kilab" testId={`review_${index}`} key={`review_${index}`}>
                
                <Row>
                    <Col sm={6} md={6}  className="headerListRow">

                        <span className="review-date ">
                            {moment(result.date ? result.date : result.date).format(`DD-MM-YYYY HH:mm`)}
                        </span>
                        <span testId={`review-title-${index}`} className={`review-ki-edited`}>{result.title}</span>

                        <Dropdown
                            isOpen={isOpen}
                            toggle={toggle}
                            className="context-menu"
                        >

                            <DropdownToggle testId={`review-context-toggle-${index}`} className="btn-info-special context-menu-button">
                                <span className="icon-info icon-lh">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                    <span className="path3"></span>
                                </span>
                            </DropdownToggle>

                            <DropdownMenu testId={`review-context-${index}`} className="context-menu-content">
                                <DropdownItem>
                                    <div testId={`context-gender-${index}`} ><span><strong>{tEditor("gender")}</strong>:</span> {tEditor(result.context.gender)}</div>
                                    <div testId={`context-birthyear-${index}`} ><span><strong>{tEditor("born-date")}</strong>:</span> {result.context.birthYear}</div>
                                    {result.context.hormoneStatus ? (
                                        <div testId={`context-hormonstatus-${index}`}>
                                            <span><strong>{tEditor("hormone-status")}</strong>: </span> 
                                            {result.context.gender === "Male" ? maleHormonStatus.find(p => p.value === result.context.hormoneStatus).state
                                            : femaleHormonStatus.find(p => p.value === result.context.hormoneStatus).state
                                            }
                                        </div>
                                    ) : (<></>)}
                                    {result.context.hormoneYear ? (<div testId={`context-hormonyear-${index}`}><span><strong>{tEditor("year")}</strong>:</span> {result.context.hormoneYear}</div>) : (<></>)}
                                    {result.context.hormoneMonth ? (<div testId={`context-hormonmonth-${index}`}><span><strong>{tEditor("pregnancy-month")}</strong>:</span> {result.context.hormoneMonth}</div>) : (<></>)}
                                    {result.context.hormoneCycle ? (<div testId={`context-hormoncycle-${index}`}><span><strong>{tEditor("cycle-day")}</strong>:</span> {result.context.hormoneCycle}</div>) : (<></>)}

                                    {result.context.additionalInfo ? (<div testId={`context-additional-${index}`}><span><strong>{tEditor("additional-info")}</strong>:</span> {result.context.additionalInfo}</div>) : (<></>)}
                                    {result.context.survey_correct ? (<div testId={`context-survey-correct-${index}`}><span><strong>{tEditor("survey-comment-correct").toLowerCase()}</strong>:</span> {tEditor(result.context.survey_correct.toLowerCase().replace(/_/g, "-").replace("i-", ""))}</div>) : (<></>)}
                                    {result.context.survey_completely ? (<div testId={`context-survey-completely-${index}`}><span><strong>{tEditor("survey-comment-complete").toLowerCase()}</strong>:</span> {tEditor(result.context.survey_completely.toLowerCase().replace(/_/g, "-").replace("i-", ""))}</div>) : (<></>)}
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>

                    </Col>
                    <Col sm={6} md={6}>
                        <span className="store-info">{tEditorStored("edited-by")} <b testId={`review-author-${index}`}>{result.physician}</b></span>
                    </Col>
                    <Col md={1} className="align-right">
                        {/*
                            result.isAutoSaveDraft ? (

                                <span className="draft-icon"><Icon file="draft_.svg" /></span>
                            ):(

                                <span className="draft-icon"></span>
                            )
                            */
                        }  
                    </Col>
                </Row>
                <Row>
                    <Col lg={9} md={12} sm={12}>
                        <div className="review-content" testId={`review-content-${index}`} dangerouslySetInnerHTML={{__html: result.description}}></div>
                    </Col>
                    <Col lg={3} md={0} sm={0} className="containerFolgen">
                        <div className="review-left-header">
                            <p>{tEditorStored("pending")}</p>
                        </div>
                        <div className="folgen-section">
                            {result && result.pendingTests.length ? result.pendingTests.map( (resultFV, indexFV) => {
                                let value = resultFV["Observation Identifier"].replace(/^([\s]*)\s/, '').split(' ');
                                return (
                                <div className="folgen-pill" key={`folgen-pill-${indexFV}`}>
                                    {`${value[1]} ${value[2]}`}
                                </div>
                                )
                            }) : (<p>{tMedicalReview("all-folgen-values-processed")}</p>)}
                        </div>
                    </Col>
                </Row>
            </div>
    )
}

const KiLabEditor_stored_list = () => {
    // Translation library
    const tEditor = useTranslate('kilab-editor-stored-list');

    const study = useSelector(state => state.kiLabReducer.study);
    const reviews = study && study.comment_history ? study.comment_history.map((r,i) => {
        return {
            context: r.data.context,
            date: r.created_at,
            title: tEditor("dr-text"),
            bornDate: "1978-11-09T23:49:02.771Z",
            aiPureResult: r.data.comment_type === 1,
            isAutoSaveDraft: r.data.comment_type === 0,
            description: r.data.text,
            status: "Nicht abgerechnet",
            pendingTests: r.folgenValues,
            statusColor: "danger",
            physician: (r.doctor.title?r.doctor.title+" ":"")  + r.doctor.firstName + " " + r.doctor.lastName
        }
    }) : [];

    return (
    <>
        {
            reviews.map((result, index) => (
                <ReviewItem key={`review-item-${index}`} result={result} index={index} />
            ))
        }
    </>
    )
}
export default KiLabEditor_stored_list;