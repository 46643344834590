import AxiosService from "../services/AxiosService";
import io from "socket.io-client";

class WsService extends AxiosService {
  constructor() {
    super("websockets");
  }
  connect = async () => {
    this.socket = io(`${window.location.origin.replace(/^(https?:\/\/)([\w-]+)(\.[\w-]+\.[a-z]+)(.*)$/, '$1api$3$4')}/`, {
      path: `/${this.preffix}/server`,
      reconnectionDelay: 10000,
      forceNew: false,
    });
    return new Promise((resolve) => {
      this.socket.on("connect", () => {
        this.sendToken();
        resolve(this.socket);
      });
    });
  };

  sendToken = () => {
    this.socket.emit("suscribe-token", this.getToken());
  };

  /**
 * Get connections by channel
 * @param {string} type channels type
 * @param {string} channels all channels separated by comma
 */
   getConnections = async (channels, type = "token") => {
    return await this.get(`/api/internal/clients/${type}/${channels}`);
  };

  disconnect = async () => {
    if (this.socket) {
      await this.socket.disconnect();
      this.socket = null;
      return "disconnected";
    }
    return "not socket";
  };
}

export default new WsService();
