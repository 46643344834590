/**
 * View to show statistics
 */
import React, { useEffect } from "react";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";

//Include Translation tool
import { useTranslate } from "react-translate";

//Include a particular scss for this view
import 'assets/scss/custom/_stats.scss';

import WidgetDoughnut from '../../views/Widgets/WidgetDoughnut';

import { useDispatch, useSelector } from "react-redux";

import { actionKilabPracticeMetrics } from "store/actions/statsActions"
import { actionLaborPracticeMetrics } from "store/actions/statsActions";
import useAuthorizationValidator from "plugins/TS-lib-utils-public/hooks/useAuthorizationValidator";


const StatsModule = ({ metricsData = [], title, type }) => {

    //Invoke to translate lib
    const t = useTranslate('stats');

    //Practice metrics
    let title1 = ""; 
    let data1 = [];   

    let title2 = "";
    let data2 = [];


    //User Metrics
    let title3 = ""; 
    let data3 = [];   

    let title4 = "";
    let data4 = [];


    /**
     * Method to complete the keyAnswers not returned on the DB Query.
     * With this function we force to have a complete estructure for each
     * array.
     * 
     * The proces makes a merge between the DB result and the complete structure
     */
    function completeArray(array2complete){

        //Format structure of the result
        let structData = [
            { "keyAnswer":"YES",         "label": t("YES"),         "value": "0", "votes": "0", "bgColor": "#fedd65", "hoverColor": "#e5fed3" },
            { "keyAnswer":"NO",          "label": t("NO"),          "value": "0", "votes": "0", "bgColor": "#f79477", "hoverColor": "#f7ebca" },
            { "keyAnswer":"I_DONT_KNOW", "label": t("I_DONT_KNOW"), "value": "0", "votes": "0", "bgColor": "#acc6f6", "hoverColor": "#edf5fe" }
        ];

        // Order desired to deliver
        const desiredOrder = ["YES", "NO", "I_DONT_KNOW"];

        //Complete the array with the unexisting keys
        structData.forEach(item => {

            //check if the element exsits in the array
            const exists = array2complete.some(stat => stat.label === item.label);
            //console.log(array2complete)
            if (!exists) {

                array2complete.push(item);  // Agregar el elemento si no está en structStatistics
            }
        });

        //If the answer doesn't exist, the record is added
        array2complete.forEach((value, index, array) => {

            //console.log(array[index])
            if( array[index].value === 'NaN'){
                
                array[index].value = "0"; 
                array[index].votes = "0"; 
            }
        });

        // Ordenar el array
        array2complete.sort((a, b) => {
            return desiredOrder.indexOf(a.keyAnswer) - desiredOrder.indexOf(b.keyAnswer);
        });

        const arrayOut = [ array2complete]

        return array2complete;
    }

    
    /**
     * Method to convert the json in the right format for the graphics components
     * From : JSON from the API
     * TO :
     *       [
     *         {label:"Ja",           value:85,  bgColor:"#7edd65",  hoverColor:"#e5fed3"},
     *         {label:"Weiss nicht",  value:10,  bgColor:"#acc6f6",  hoverColor:"#edf5fe"},
     *         {label:"Nein",         value:35,  bgColor:"#f79477",  hoverColor:"#f7ebca"}
     *       ]
     * @param {} questions 
     * @returns 
     */
    const processStatistics =  (questions) => {

        return questions.map((question) => {
            // General total votes
            const generalVotes = question.answers.reduce((sum, answer) => sum + answer.totalVotes, 0);

            /*
            console.log("+++++++++++")
            console.log(question.answers)
            */

            let general = []
            // General statistics
            general = question.answers.map((answer) => ({
                        keyAnswer : answer.keyAnswer,
                        label: t(answer.keyAnswer),
                        votes: answer.totalVotes,
                        value: ((answer.totalVotes / generalVotes) * 100).toFixed(2),
                        bgColor: answer.bgColor,
                        hoverColor: answer.hoverColor
            }));

            // Practice statistics
            let practice = [];
            
            //To get the practices metrics, set to true the conditional
            //Otherwise we process without sens.
            if( true ){
                const practiceData = question.answers.flatMap((answer) => 
                    answer.practices.map((practice) => ({
                            keyAnswer : answer.keyAnswer,
                            label: t(answer.keyAnswer),     
                            votes: practice.practiceTotalVotes,
                            bgColor: answer.bgColor,
                            hoverColor: answer.hoverColor
                    }))
                );
        
                const totalPracticeVotes = practiceData.reduce((sum, practice) => sum + practice.votes, 0);
        
                practice = practiceData.map((practice) => ({
                            keyAnswer : practice.keyAnswer,
                            label: practice.label,
                            votes: practice.votes,
                            value: ((practice.votes / totalPracticeVotes) * 100).toFixed(2),
                            bgColor: practice.bgColor,
                            hoverColor: practice.hoverColor
                }));
            }
            

            // Doctor statistics
            const doctorData = question.answers.flatMap((answer) =>
                answer.practices.flatMap((practice) => 
                    practice.particularVotes.map((vote) => {

                        return {
                            keyAnswer : answer.keyAnswer,
                            label: t(answer.keyAnswer),
                            votes: vote.votes,
                            bgColor: answer.bgColor,
                            hoverColor: answer.hoverColor
                        };
                    })
                )
            );

            const totalDoctorVotes = doctorData.reduce((sum, doctor) => sum + doctor.votes, 0);

            let doctor = doctorData.map((doctor) => ({
                keyAnswer : doctor.keyAnswer,
                label: doctor.label,
                votes: doctor.votes,
                value: ((doctor.votes / totalDoctorVotes) * 100).toFixed(2),
                bgColor: doctor.bgColor,
                hoverColor: doctor.hoverColor
            }));


            //Method to complete the answers array.
            //Could have a partial answers when a Doctor start from cero
            general = completeArray(general);
            doctor = completeArray(doctor);
            practice = completeArray(practice);


            console.log("doctor")
            console.log(doctor)

            return {
                question: question.questionKey,
                general,
                practice,
                doctor
            };
        });
    };
        
    
    //wait for the object from the backend
    if (metricsData && metricsData.length) {

        /*
        console.log("metricsData")
        console.log(metricsData)
        */

        // Process data
        const result = processStatistics(metricsData);

        //console.log(type, result);

        //Fill the practioce metrics
        title1 = t(result[0].question);   //dat1.title; 
        data1 = result[0].doctor;     //dat1.data;  
        
        
        console.log("data1")
        console.log(data1[0].votes)
        

        title2 = t(result[1].question);   //dat2.title;
        data2 = result[1].doctor      //;dat2.data;


        //**************************** */

        //Fill the practioce metrics
        title3 = t(result[0].question);   //dat1.title; 
        data3 = result[0].practice;     //dat1.data;   
        
        console.log( "result[1]" )
        console.log( result[1] )

        title4 = t(result[1].question);   //dat2.title;
        data4 = result[1].practice      //;dat2.data;
    }

    return (
        <>
            <CardBody>
                <h5 className="general-card-subtitle">{title}</h5>
                <div>{t('description')}</div>
            </CardBody>

            <CardBody>

                {data1.length > 0 || data2.length > 0 ? (
                    <div className="divRadiusCont">
                        <p>{t('my_reviews')} <small><b>({data1.reduce((acc, item) => (1*acc) + (0+1*item.votes), 0)})</b></small></p>
                        {data1.length > 0 && <WidgetDoughnut id={`${type}_w_1`} key={`${type}_w_1`} data={data1} dataTitle={title1} />}
                        {data2.length > 0 && <WidgetDoughnut id={`${type}_w_2`} key={`${type}_w_2`} data={data2} dataTitle={title2} />}
                    </div>
                ) : (<></>)}
                
                {data3.length > 0 || data4.length > 0 ? (
                    <div className="divRadiusCont">
                        <p>{t('all_reviews')} <small><b>({data3.reduce((acc, item) => (1*acc) + (0+1*item.votes), 0)})</b></small></p>
                        {data3.length > 0 && <WidgetDoughnut id={`${type}_w_3`} key={`${type}_w_3`} data={data3} dataTitle={title3} />}
                        {data4.length > 0 && <WidgetDoughnut id={`${type}_w_4`} key={`${type}_w_4`} data={data4} dataTitle={title4} />}
                    </div>
                ) : (<></>)}
                
            </CardBody>
        </>
    );
}

/**
 * 
 * Main module who invoke a StatsModule for each stats module
 */
const Stats = () => {

    const t = useTranslate('stats');
    //Hoock para que el componente llame a Redux y pueda invocar acciones
    const dispatch = useDispatch();

    //Obtengo el ID del a Practice
    const activePracticeId = useSelector(state => state.practicesReducer.practice?._id) || 0;

    //Obtengo el auth User Id
    const user = useSelector(state => state.userReducer);

    //Get data from the STATE
    const kilabMetricsData = useSelector( (state) => state.statsReducer.kilabData);
    const laborMetricsData = useSelector( (state) => state.statsReducer.laborData);

    const { checkAccess } = useAuthorizationValidator(activePracticeId);

    //obtengo los datos de las metricas orientados a la practice activa
    useEffect(() => {

        //Questions to get from the Back
        const keyQuestion1 = "AI_COMMENT_TECHNICALLY_CORRECT?";
        const keyQuestion2 = "AI_COMMENTARY_COMPLETE?";
        const keyQuestionComposite = [keyQuestion1, keyQuestion2]

        const doctorAuthId = user.auth_user_id;;
        
        if (checkAccess("kilab-module", "statistics")) { 
            dispatch( actionKilabPracticeMetrics(keyQuestionComposite, activePracticeId, doctorAuthId) );
        }

        if (checkAccess("practice-laboratory", "statistics")) {
            dispatch( actionLaborPracticeMetrics(keyQuestionComposite, activePracticeId, doctorAuthId));
        }
        
        // [] indica se usa solo una vez y al inicio de la vista
    }, [activePracticeId]);
    //console.log("Practice active: ", activePracticeId)

    return (

        <Card className="full-height">
            <CardBody >
                <Card>
                    <CardHeader>
                        <CardTitle className="d-flex align-content-center">
                            <div className="general-card-title-img text-primary mr-2">
                                <i className="icon-TP_KI_Logo_White" />
                            </div>
                            <div className="general-card-title text-primary">{t('title')}</div>
                        </CardTitle>
                    </CardHeader>

                    {checkAccess("kilab-module", "statistics") ? (<StatsModule type="kilab" title={t('kilab-title')} metricsData={kilabMetricsData}/>) : (<></>) }
                    {checkAccess("practice-laboratory", "statistics") ? (<StatsModule type="labor" title={t('labor-title')} metricsData={laborMetricsData}/>) : (<></>)}
                    {!checkAccess("kilab-module", "statistics") && !checkAccess("practice-laboratory", "statistics") ? (
                        <p>No permissions detected</p>
                    ) : (<></>)}
                </Card>
            </CardBody>
        </Card>
    )
};

export default Stats;
