import { useFileUtils } from 'hooks';
import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form } from 'reactstrap';
import { useTranslate } from 'react-translate';
import { toast } from 'react-toastify';
import ChatsService from 'services/ChatsService';
import { actionUpdateChatInList } from 'store/actions/chatsActions';
import { actionSetChat } from 'store/actions/chatsActions';

const ChatCreateGroup = ({ chatName, setShowChatForm, setEditChatMembers }) => {
  const t = useTranslate('chat');
  const practice = useSelector((state) => state.userAuthorizationReducer);
  const dispatch = useDispatch();
  const { handleSubmit, register } = useForm();

  const inputAvatar = document.getElementById('chat-avatar');

  const { handleSelectFile, file, resetInput } = useFileUtils(inputAvatar);

  const activePractice = useSelector((state) => state.practicesReducer.practice);
  const isMounted = useRef(false);

  const onSubmit = async (data) => {
    if (!data.name) {
      return toast.error( t("name-of-group") ); //'Write a name for the group');
    }
    chatName.current = data.name;
    ChatsService.saveChat(practice._id, data)
      .then((response) => {
        if (response.status >= 400) {
          if (response.responseText) {
            return toast.error(t('alert-error-group-repeated'));
          }
          return toast.error(t('alert-error-save-chat'));
        } else {
          const chat = response.data;
          delete chat.messages;
          dispatch(actionUpdateChatInList(chat, true));
          dispatch(actionSetChat(chat));
          resetInput();
          setShowChatForm(false);
          setEditChatMembers(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Return to the main view when the practice changes
  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
    } else {
      if (activePractice){
        setShowChatForm(false);
        resetInput();
      }
    }
    //eslint-disable-next-line
  }, [activePractice]);

  return (
    <>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        className="chat-create-group"
        encType="multipart/form-data"
      >
        <div className="chat-create-group">
          <input
            ref={register}
            type="file"
            name="avatar"
            id="chat-avatar"
            accept="image/*"
            className="d-none"
            onChange={handleSelectFile}
          />
          <div className="chat-create-group-text">
            {t('group-create-prompt')}
          </div>

          <div className="create-group-form">
            <div className="group-form-header">{t('group-name-title')}</div>
            <div className="d-flex">
              <div className="create-group-img">
                <img
                  src={
                    file && file.hasOwnProperty('file')
                      ? file.file
                      : '/General/avatar.png'
                  }
                  alt="Group's avatar"
                  onClick={() => {
                    document.getElementById('chat-avatar').click();
                  }}
                />
              </div>

              <div className="input_user_search">
                <input
                  type="text"
                  className="user_search"
                  name="name"
                  ref={register}
                />

                <div className="create-group-buttons d-flex justify-content-end">
                  <Button color="info" size="sm" type="submit">
                    {t('button-ok')}
                  </Button>
                  <Button
                    color="primary"
                    size="sm"
                    onClick={() => {
                      setShowChatForm(false);
                      resetInput();
                    }}
                  >
                    {t('button-cancel')}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </>
  );
};

export default ChatCreateGroup;
