import AxiosService from 'plugins/TS-lib-utils-public/services/AxiosService';

/** TODO, change name */
class LaboratoryService extends AxiosService {
    constructor() {
        super('doctor-user');
    }
    /**
  * Get all studies linked to the current doctor and practice
  */
    getStudiesList = async (practiceId, page = 0, count = 5, sortDate = 0, search = null, dateFrom = null, dateTo = null, { cancelToken = null } = {}) => {
        let query = `/early-lab/?page=${page}&limit=${count}&datesort=${sortDate}`;

        if (search) {
            query += `&search=${search}`
        }
        if (dateFrom) {
            query += `&from=${dateFrom}`;
        }
        if (dateTo) {
            query += `&to=${dateTo}`;
        }
        
        const response = await this.get(query, {
            headers: {
                Authorization: `Bearer ${this.getToken()}`,
                practice: practiceId,
            },
            cancelToken: cancelToken
        });
        return response;
    };
    /**
    * Get get study detail by reportId
    */
    getStudyDetail = async (reportId, practiceId, registerDoctor = false) => {
        const response = await this.get(`/early-lab/${reportId}`, {
            headers: {
                Authorization: `Bearer ${this.getToken()}`,
                practice: practiceId,
                assignment: registerDoctor
            },
        });
        return response;
    };

    sendReview = async (reportId, review, practiceId, hl7_id, context, type) => {
        const response = await this.post(`/early-lab/${reportId}/review`, {
            review,
            hl7_id,
            context,
            type
        }, {
            headers: {
                Authorization: `Bearer ${this.getToken()}`,
                practice: practiceId,
            },
        });
        return response;
    }

    saveDraft = async (reportId, review, practiceId, hl7_id, context, type) => {
        const response = await this.post(`/early-lab/${reportId}/draft`, {
            review,
            hl7_id,
            context,
            type
        }, {
            headers: {
                Authorization: `Bearer ${this.getToken()}`,
                practice: practiceId,
            },
        });
        return response;
    }

    saveContext = async (reportId, context) => {
        const response = await this.post(`/early-lab/${reportId}/context`, {
            context
        }, {
            headers: {
                Authorization: `Bearer ${this.getToken()}`,
            },
        });
        return response;
    }

    generateAIComment = async (reportId) => {
        const response = await this.get(`/early-lab/${reportId}/generate-ai`, {
            headers: {
                Authorization: `Bearer ${this.getToken()}`,
            },
        });
        return response;
    }

    surveyVote = async (practiceId, option1, option2, { cancelToken = null }) => {
        const response = await this.post(`/early-lab/vote`, {
            survey_correct: option1,
            survey_completely: option2
        },{
            headers: {
                Authorization: `Bearer ${this.getToken()}`,
                practice: practiceId
            },
            cancelToken: cancelToken
        });
        return response;
    }

    removeDoctor = async (doctorId) => {
        const response = await this.get(`/early-lab/remove-doctor/`, {
            headers: {
                Authorization: `Bearer ${this.getToken()}`,
                doctor: doctorId
            },
        });
        return response;
    }
}

export default new LaboratoryService();