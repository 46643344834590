import React, { useState,useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Col, Row
} from 'reactstrap';
import { useHistory } from 'react-router';
import { useTranslate } from "react-translate";
import Icon from 'components/Icons/Icon';
import { actionRefreshPermissions } from 'store/actions/usersActions.js';
import { actionSetMessage } from 'store/actions/messagesActions';
import useAuthorizationValidator from 'plugins/TS-lib-utils-public/hooks/useAuthorizationValidator';
import {  actionChatFilter } from 'store/actions/chatsActions';
import { actionGetPractice } from 'store/actions/practicesActions';
import { toast } from 'react-toastify';


function PracticesMenu() {
  const history = useHistory();
  const [dropdownOpenHelp, setdropdownOpenHelp] = useState(false);
  const dispatch = useDispatch();
  const { practices } = useSelector((state) => state.userReducer);
  const practice = useSelector((state) => state.userAuthorizationReducer);
  const practiceList = useSelector((state) => state.practicesReducer.practices);
  const practiceData = useSelector((state) => state.practicesReducer.practice);
  const [routes, setRoutes] = useState('');
  const { checkAccess } = useAuthorizationValidator();
  const t = useTranslate('practices');

  const dropdownToggleHelp = () => {
    
    setdropdownOpenHelp(!dropdownOpenHelp);
  };

  const redirectToPage = (id) => {
    // const validRoutes = {
    //   dashboard: '/admin/dashboard',
    //   chat: '/admin/chat',
    //   root: '/',
    // };
    // setPractice(id);
    // const route = Object.keys(validRoutes).find((i) => checkAccess(i));
    // if (route) {
    //   setRoutes(validRoutes[route]);
    //   // history.push(validRoutes[route]);
    //   if(routes != ''){
    //      dispatch(actionGetPractice(id));
    //   }
     
    // }

    //check if I have permission in dashboard
    const allowDashboard = checkAccess('dashboard', null,id);
    if(allowDashboard){

      setRoutes('/admin/dashboard');
      //check permission of the practice selected
      dispatch(actionGetPractice(id));
    }else{

      //if my permissions are limited 
      setRoutes('/admin/chat');
      dispatch(actionGetPractice(id));
    }
  };

  useEffect(() => {

    if (practiceData && routes !== '') {
      setRoutes('');
      toast.info( t('toast-redirect')+ ' ' +practiceData.name);
      history.push('/admin/dashboard');
    }
    //eslint-disable-next-line
  }, [dispatch, practiceData]);


  const handlePractice = (id) => {

    dispatch(actionChatFilter(false));
    dispatch(actionSetMessage({}));
    dispatch(actionRefreshPermissions(id));
    redirectToPage(id);
  };

  /*
  //Agregar esta clase para que desaparezca el nombre de la practice en resoluciones pequeñas
  .span_cont_practice_name{
    display: none;
  }

  */

  return (
            <Dropdown
              nav
              isOpen={dropdownOpenHelp}
              toggle={dropdownToggleHelp}
              className="practices-menu"
            >
              <DropdownToggle caret nav>
                <span className='span_cont_practice_name'>{practiceData.name} &nbsp;</span>
                <Icon file="hospital.svg" width="21px" title="Practices" />
                <p>
                  <span className="d-none">{t('th-practice')}</span>
                </p>
              </DropdownToggle>
              <DropdownMenu right>
                {practices &&
                  practiceList && 
                  practiceList.map((key) => {
                    if (key.invisible) {
                      return null;
                    }
                    return (
                    <DropdownItem
                      tag="a"
                      href="#"
                      key={`pratice-item-${key._id}`}
                      onClick={() => handlePractice(key._id || null)}
                      className={
                        practice?._id === key?._id ? 'practice-active' : ''
                      }
                    >
                      <span>{key.name || ''}</span>
                    </DropdownItem>
                  )})}
              </DropdownMenu>
            </Dropdown>
  );
}

export default PracticesMenu;
