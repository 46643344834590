import React, { useEffect, useState } from 'react';
import usePushNotifications from 'hooks/usePushNotifications';
import SweetAlert from 'react-bootstrap-sweetalert';

import { toast } from 'react-toastify';
import PushNotificationService from 'services/PushNotificationsService';
import { FormGroup, Button } from 'reactstrap';
import { useTranslate } from 'react-translate';

import { useSelector } from 'react-redux';
import nativeSender from 'helpers/nativeSender';

export default function PushNotification() {
  const t = useTranslate('settings');
  const [alert, setAlert] = useState(null);
  const {
    pushNotificationSupported,
    userSubscription,
    askUserPermission,
    error,
  } = usePushNotifications();

  const { nativeWebView } = useSelector((state) => state.commonReducer);

  const handleUnsubscribe = () => {
    setAlert(
      <SweetAlert
        warning
        title={t('are-you-sure')}
        onConfirm={async () => {
          try {
            if (userSubscription) {
              PushNotificationService.pushUnsusbcribe(userSubscription);
              /*setUserSubscription(null);*/
              return toast.success(t('unsubscription-success'));
            }
          } catch (error) {
            console.log(error);
            return;
          } finally {
            setAlert(null);
          }
        }}
        onCancel={() => {
          setAlert(null);
        }}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText={t('btn-unsubscribe')}
        cancelBtnText={t('btn-cancel')}
        showCancel
      >
        {t("cancel-subscription-prompt")}
      </SweetAlert>
    );
  };
  useEffect(() => {
    if (error) {
      console.log(error);
    }
  }, [error]);

  if (!pushNotificationSupported && !nativeWebView) {
    return (
      <div className="setting-section">
        <h5 className="general-card-subtitle">{t('notifications-title')}</h5>
        <p>{t('notifications-unsupported')}</p>
      </div>
    );
  }

  return (
    <div className="setting-section">
      {alert}

      <h5 className="general-card-subtitle">{t('notifications-title')}</h5>
      <FormGroup>
        {/*
        <Row>
          <Col md="12">
            <CustomInput
              type="switch"
              id="push-notifications-activator"
              className="custom-switch-settings"
              label=""
              defaultChecked={switchChecked}
              onChange={handleChange}
              innerRef={switchRef}
              inline
            />
            <label>{t("enable-notifications")}</label>
          </Col>
        </Row>
      */}
        {/*`${t('you-want-notifications')}: ${userSubscription ? "enabled" : "disabled"}`*/}
        <div className="notification-section">
          <Button
            color="info"
            size="sm"
            name="btn-activate-2fa"
            onClick={() => {
              if (nativeWebView) {
                nativeSender.send("SUBSCRIBE");
                toast.success(t('subscription-success'));
              } else {
                askUserPermission();  
              }
            }}
          >
            {t('enable-notifications')}
          </Button>
          <Button
            color="primary"
            type="cancel"
            size="sm"
            name="btn-unactivate-2fa"
            onClick={() => {
              if (nativeWebView) {
                nativeSender.send("UNSUBSCRIBE");
                toast.success(t('unsubscription-success'));
              } else {  
                handleUnsubscribe();
              }
            }}
          >
            {t('disable-notifications')}
          </Button>
        </div>
      </FormGroup>
    </div>
  );
}
