import React from "react";
import DoctorReviewField from "./Review/DoctorReviewField";
import DoctorReviewHistory from "./Review/DoctorReviewHistory";

const DoctorReview = ({study, reportId, review, setReview, aiFlag, setAiFlag, readAiComment, setReadAiComment, systemTemplates}) => {
    return (
        <>
            <DoctorReviewField 
                study={study} 
                reportId={reportId} 
                review={review} 
                setReview={setReview} 
                aiFlag={aiFlag} 
                setAiFlag={setAiFlag}
                readAiComment={readAiComment}
                setReadAiComment={setReadAiComment}
                systemTemplates={systemTemplates}
            />
            <DoctorReviewHistory study={study} reportId={reportId} />
        </>
    )
}

export default React.memo(DoctorReview);