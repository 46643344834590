import { takeEvery, put, call, select, takeLatest, take } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import momentjsI18n from 'helpers/momentjsI18n';
import checkPermission from 'helpers/checkPermission';
import { persistor } from 'store/store';
//Redux Actions
import CONSTANTS from '../CONSTANTS';
import {
  actionLogin,
  actionSetUser,
  actionGetUser,
  actionSetQr2fa,
  actionSetLoadingUsers,
  actionSetLang,
  actionSetDateFormat,
  actionRefreshPermissions,
  actionLogoutClear,
  actionSetActiveSessions,
  actionGetActiveSessions,
  actionRefreshUser
} from '../actions/usersActions';
import {
  actionGetPractice,
  actionGetPractices,
  actionSetPractice,
} from '../actions/practicesActions';
import {
  actionResetUserAuthorization,
  actionSetUserAuthorization,
} from '../actions/userAuthorizationActions';
import { actionSetLoading } from 'store/actions/loadingActions';
import { actionRefreshBoxes } from 'store/actions/messagesActions';
import { actionResetApp } from 'store/actions/rootActions';
//Services
import AuthService from '../../plugins/TS-lib-utils-public/services/AuthService.js';
import { actionGetChats, actionSetChat } from 'store/actions/chatsActions';
import historyRouter from 'historyRouter';
//Helper
import { urlsMap } from 'helpers/urlsBlob';
//Translations
import utilTranslations from '../../helpers/utilTranslations';
import { getUser, getNativeWebView, getMobile } from 'store/selectors/selectors';
import nativeSender from 'helpers/nativeSender';
import ProfilesService from 'services/ProfilesService';
import { actionNotificationsSubscribe } from 'store/actions/notificationsActions';
import PracticesService from 'services/PracticesService';
import { actionSetChats } from 'store/actions/chatsActions';
AuthService.setBaseUrl('/doctors');

function* sagaGetUser() {
  const userActive = yield select(getUser);
  //Translations
  let t = utilTranslations(userActive.language || 'de', 'users');
  try {
    yield put(actionSetLoading(true));
    let user = yield call(AuthService.getUser);
    if (user.status === 401 || !user?.data) {
      yield call(AuthService.forceRemoveToken());
      historyRouter.push('/');
      throw new Error(t('alert-error-unauthorized'));
    }
    if (user.data) {
      yield put(actionSetUser(user.data));
      yield put(actionNotificationsSubscribe());
    }
    //Set last active practice
    if (user.data?.practices) {
      let practice_active = yield call(PracticesService.getPracticeBySession);

      if (practice_active.status >= 400) {
        throw new Error("Session not found");
      }

      practice_active = practice_active.data?.id;

      const practiceId = practice_active
        ? practice_active
        : Object.keys(user.data.practices)[0];
      if (practiceId) {
        yield put(actionGetPractices(practiceId));
        yield put(actionSetUserAuthorization(user.data.practices[practiceId]));
        // yield put(actionRefreshPermissions());
      }
    }
  } catch (error) {
    console.log(error);
  } finally {
    yield put(actionSetLoadingUsers(false));
    yield put(actionSetLoading(false));
  }
}
/**
 * Refresh user data
 */
function* sagaRefreshUser() {
  const userActive = yield select(getUser);
  //Translations
  let t = utilTranslations(userActive.language || 'de', 'users');
  try {
    // yield put(actionSetLoading(true));
    let user = yield call(AuthService.getUser);
    if (user.status === 401) {
      yield call(AuthService.forceRemoveToken());
      throw new Error(t('alert-error-unauthorized'));
    }
    if (user.data) {
      yield put(actionSetUser(user.data));
    }
  } catch (error) {
    // console.log(error);
    // toast.error('Bad request');
  } finally {
    // yield put(actionSetLoading(false));
  }
}
/**
 * Refresh permissions
 */
function* sagaRefreshPermissions({ practiceId, setFirst }) {

  const userActive = yield select(getUser);
  //Translations
  let t = utilTranslations(userActive.language || 'de', 'users');
  
  try {
    let user = yield call(AuthService.getUser);
    const mobile = yield select(getMobile);
    if (user.status >= 400) {
      throw new Error(t('alert-error-user-not-found'));
    }
    if (user.data.practices && Object.keys(user.data.practices).length > 0) {
      //Set last active practice
      let id = practiceId ? practiceId : Object.keys(user.data.practices)[0];
      if (
        !setFirst &&
        !practiceId &&
        user.data.practice_active &&
        user.data.practices[user.data.practice_active]
      ) {
        id = user.data.practice_active;
      }
      let options = {};
      if (user.data && user.data._id && id) {
        options = {
          practiceActive: true,
          chatStatus: 'available',
        };
      }
      const permissions = user.data.practices[id];
      yield put(actionGetPractices());
      yield put(actionGetPractice(id, options));
      yield take(CONSTANTS.PRACTICES_SET);
      yield put(actionSetUserAuthorization(permissions));
      yield put(actionSetUser(user.data));
      /**
       * Refresh all of the message boxes
       */
      if (checkPermission({ permissions, moduleKey: 'inbox' })) {
        yield put(actionRefreshBoxes());
      }
      /**
       * For chats
       */
      if (checkPermission({ permissions, moduleKey: 'chat' })) {
        yield put(actionSetChat({}));
        //yield put(actionSetChats({}));
        yield put(actionGetChats(true));
      }
      /**
       * Practice change notification
       */
      toast.success( t('practice-selected') );
    } else {
      yield put(actionGetPractices());
      yield put(actionSetPractice({}));
      yield put(actionResetUserAuthorization());
    }
  } catch (error) {
    console.log(error);
    toast.error(t('bad-request'));
  }
}

function* sagaRegister({ user }) {

  const userActive = yield select(getUser);
  //Translations
  let t = utilTranslations(userActive.language || 'de', 'users');

  try {
    //Register
    const result = yield AuthService.register(user);
    if (result && result.status === 201) {
      yield put(actionLogin(user));
    } else {
      throw new Error("The email is already registered");
    }
  } catch (error) {
    console.log(error);
    toast.error(t('bad-request'));
  }
}

function* sagaLogoutBase() {
  const userActive = yield select(getUser);
  //Translations
  let t = utilTranslations(userActive.language || 'de', 'users');

  try {
    yield call(actionLogoutClear);
    yield AuthService.logout();
    yield persistor.purge();
    yield put(actionResetApp());
    urlsMap.clear();

    //Clear all message drafts
    for (let key in localStorage) {
      if (key.includes('input-message-draft-')) {
        localStorage.removeItem(key);
      }
    }
    yield put(actionSetLoadingUsers(false));
  } catch (error) {
    toast.error(t('bad-request'));
  }
}

function* sagaLogout() {

  const userActive = yield select(getUser);
  //Translations
  let t = utilTranslations(userActive.language || 'de', 'users');

  try {
    const mobile = yield select(getNativeWebView);
    yield put(actionSetLoadingUsers(true));
    if (mobile) {
      //Send logout event to webview
      yield call(nativeSender.send, 'LOGOUT');
    } else {
      yield call(sagaLogoutBase);
    }
  } catch (error) {
    toast.error(t('bad-request'));
  }
}

function* sagaLogin({ user }) {
  const userActive = yield select(getUser);
  //Translations
  let t = utilTranslations(userActive.language || 'de', 'users');

  try {
    yield put(actionSetLoading(true));
    // toast.info("Starting");
    let login = yield call(AuthService.login, user);
    if (login.status === 'pending') {
      return yield put(actionSetUser({ ...login, auth_2fa: login.status }));
    }
    if (login.status === 'success') {
      if (!login.password_expired) {
        yield put(actionNotificationsSubscribe());

        //Send login information to webview
        yield call(nativeSender.send, 'LOGIN', login);
      }

      if (login.practices && Object.keys(login.practices).length > 0) {
        return yield put(actionRefreshPermissions());
      } else {
        return yield put(actionSetUser(login));
      }

    }
    throw new Error(t("alert-error-unauthorized"))
  } catch (error) {
    toast.error(t('login-fail'));
    console.log(error.message || t('bad-request'));
    historyRouter.push("/auth/login-page");
  } finally {
    yield put(actionSetLoading(false));
  }
}

function* sagaCheckSession() {
  try {
    yield call(AuthService.checkSession);
  } catch (error) {
    console.log(error);
  }
}

function* sagaGetSessions({ }) {
  try {
    const sessions = yield call(AuthService.getSessionList);

    return yield put(actionSetActiveSessions(sessions.data));
  } catch (error) {
    console.log(error);
    
  }
}

function* sagaDeleteSession({ session }) {
  try {
    yield call(AuthService.deleteSession, session);
    return yield put(actionGetActiveSessions());
  } catch (error) {
    console.log(error);
    
  }
}

//Auth2fa
function* sagaCheck2fa({ code }) {
  const userActive = yield select(getUser);
  //Translations
  let t = utilTranslations(userActive.language || 'de', 'users');
  try {
    toast.info(t('alert-info-check-2fa'));
    const check2fa = yield AuthService.check2fa(code);
    if (check2fa.status === 200) {
      // yield put(actionGetUser());
      return window.location.reload();
    } else {
      toast.error(t('alert-error-invalid-2fa'));
    }
  } catch (error) {
    toast.error(t('bad-request')); //'Bad request'
  }
}

function* sagaActivate2fa({ code }) {
  const userActive = yield select(getUser);
  //Translations
  let t = utilTranslations(userActive.language || 'de', 'users');
  try {
    const activate2fa = yield AuthService.activate2fa(code);
    if (activate2fa.status === 200) {
      toast.success(t('alert-success-2fa-actived'));
      yield put(actionGetUser());
    } else {
      toast.error(t('alert-error-2fa-failed'));
    }
  } catch (error) {
    console.log(error);
  }
}
function* sagaInactivate2fa({ pass }) {
  const userActive = yield select(getUser);
  //Translations
  let t = utilTranslations(userActive.language || 'de', 'users');
  try {
    const inactivate2fa = yield AuthService.inactivate2fa(pass);
    if (inactivate2fa.status === 200) {
      yield put(actionGetUser());
      toast.success(t('alert-success-2fa-disabled'));
    } else {
      toast.error(t('alert-error-2fa-disabled'));
    }
  } catch (error) {
    toast.error(t('bad-request'));
  }
}

function* sagaCreate2fa() {
  try {
    yield put(actionSetLoadingUsers(true));
    const auth2fa = yield AuthService.create2fa();
    if (auth2fa.data.key) {
      yield put(
        actionSetQr2fa({ key: auth2fa.data.key, uri: auth2fa.data.uri })
      );
    }
    yield put(actionSetLoadingUsers(false));
  } catch (error) {
    toast.error('Error 2FA Creation');
  }
}

function* sagaUpdateLang({ language }) {
  //Translations
  let t = utilTranslations(language || 'de', 'users');
  try {
    const user = { language };
    const result = yield AuthService.edit(user);
    if (result && result.status === 200) {
      toast.success(t('alert-success-lang-updated'));
      yield put(actionSetLang(language));
    }
  } catch (error) {
    toast.error(t('alert-error-lang-updated'));
  }
}

function* sagaUserEdit({ user }) {
  const userActive = yield select(getUser);
  //Translations
  let t = utilTranslations(userActive.language || 'de', 'users');
  try {
    const result = yield AuthService.edit(user);
    if (result && result.status === 200) {
      toast.success(t('alert-success-user-updated'));
      yield put(actionGetUser());
    }
  } catch (error) {
    toast.error(t('alert-error-user-updated'));
  }
}

function* sagaUpdateDateFormat({ dateFormat }) {
  const userActive = yield select(getUser);
  //Translations
  let t = utilTranslations(userActive.language || 'de', 'users');
  try {
    const user = { dateFormat };
    const result = yield AuthService.edit(user);
    if (result && result.status === 200) {
      toast.success(t('alert-success-date-format'));
      momentjsI18n.setFormat(dateFormat);
      yield put(actionSetDateFormat(dateFormat));
    }
  } catch (error) {
    toast.error(t('alert-error-date-format'));
  }
}

function* sagaChangePassword({ user }) {
  const userActive = yield select(getUser);
  //Translations
  let t = utilTranslations(userActive.language || 'de', 'users');
  try {
    //Change password
    const result = yield AuthService.changePassword(user);
    if (result) {
      if (result.status >= 400) {
        throw new Error('Error while changing the password');
      }
      yield call(nativeSender.send, 'LOGIN', userActive);
      yield put(actionGetUser());
      toast.success(t('alert-success-change-password'));
    }
  } catch (error) {
    console.log(error);
    toast.error(t('alert-error-change-password'));
  }
}

function* sagaRecoverPassword({ email }) {
  const userActive = yield select(getUser);
  //Translations
  let t = utilTranslations(userActive.language || 'de', 'users');
  try {
    //Send recovery password to email
    const result = yield AuthService.recoverPassword(email);
    if (result && result.status === 200) {
      toast.success(t('alert-success-password-recovery'));
      // toast.success('A recovery password has been sent to your email.');
      historyRouter.push('/auth/login-page');
    } else {
      toast.warning(t('alert-warning-password-recovery'));
    }
  } catch (error) {
    toast.error(t('alert-error-password-recovery'));
    // toast.error('An error ocurred while processing your request.');
  }
}

function* sagaDeleteAccountRequest({ }) {

  const userActive = yield select(getUser);
  //Translations
  let t = utilTranslations(userActive.language || 'de', 'users');

  try {

    //Send recovery password to email
    const result = yield AuthService.requestAccountDeletion("doctor");
    if (result && result.status === 200) {

      toast.success( t('alert-success-mail') ); //"Eine Bestätigungs-E-Mail wurde an Sie gesendet."
      // toast.success('A recovery password has been sent to your email.');
    } else {

      toast.warning(t('alert-warning-password-recovery'));
    }
  } catch (error) {

    toast.error(t('alert-error-password-recovery'));
    // toast.error('An error ocurred while processing your request.');
  }
}


function* sagaDeleteAccountPrepare({ user }) {

  const userActive = yield select(getUser);
  //Translations
  let t = utilTranslations(userActive.language || 'de', 'users');


  try {
    //Prepare account for deletion
    const result = yield AuthService.prepareAccountDeletion("doctor", user);
    if (result && result.status === 200) {

      yield put(actionSetLoading(false));
      toast.success(t("alert-do-login") ); //"Bitte melden Sie sich an, um Ihre Auswahl zu bestätigen."
      // toast.success('A recovery password has been sent to your email.');
    }
  } catch (error) {
    console.log(error);
    // toast.error('An error ocurred while processing your request.');
  }
}

function* sagaConfirmAccountDeletion({ userId, choice }) {
  try {
    //Confirm account deletion
    const result = yield AuthService.confirmAccountDeletion("doctor", userId, choice);
    if (result && result.status === 200) {
      toast.success(choice ? "Dein Account wurde gelöscht." : "Ihr Konto wurde wiederhergestellt.");
      if (!choice) {
        yield put(actionRefreshUser());
        historyRouter.push("/admin");
      }
    }
  } catch (error) {
    console.log(error);
    // toast.error('An error ocurred while processing your request.');
  }
}

export function* usersSaga() {
  console.log('*Main Users Saga');
  yield takeEvery(CONSTANTS.USERS_LOGIN, sagaLogin);
  yield takeEvery(CONSTANTS.USERS_LOGOUT, sagaLogout);
  yield takeEvery(CONSTANTS.USERS_LOGOUT_BASE, sagaLogoutBase);
  yield takeEvery(CONSTANTS.USERS_CHECK_SESSION, sagaCheckSession);
  yield takeEvery(CONSTANTS.USERS_REGISTER, sagaRegister);
  yield takeEvery(CONSTANTS.USERS_GET, sagaGetUser);
  yield takeEvery(CONSTANTS.USERS_REFRESH_PERMISSIONS, sagaRefreshPermissions);
  yield takeEvery(CONSTANTS.USERS_CHANGE_PASSWORD, sagaChangePassword);
  yield takeEvery(CONSTANTS.USERS_RECOVER_PASSWORD, sagaRecoverPassword);
  yield takeEvery(CONSTANTS.USERS_EDIT, sagaUserEdit);
  yield takeLatest(CONSTANTS.USERS_REFRESH_DATA, sagaRefreshUser);
  yield takeEvery(CONSTANTS.USERS_DELETE_ACCOUNT_REQUEST, sagaDeleteAccountRequest);
  yield takeEvery(CONSTANTS.USERS_DELETE_ACCOUNT_PREPARE, sagaDeleteAccountPrepare);
  yield takeEvery(CONSTANTS.USERS_DELETE_ACCOUNT, sagaConfirmAccountDeletion);
  //Auth2fa
  yield takeEvery(CONSTANTS.AUTH2FA_CREATE, sagaCreate2fa);
  yield takeEvery(CONSTANTS.AUTH2FA_ACTIVATE, sagaActivate2fa);
  yield takeEvery(CONSTANTS.AUTH2FA_INACTIVATE, sagaInactivate2fa);
  yield takeEvery(CONSTANTS.AUTH2FA_CHECK, sagaCheck2fa);
  //Settings
  yield takeEvery(CONSTANTS.USERS_UPDATE_LANG, sagaUpdateLang);
  yield takeEvery(CONSTANTS.USERS_UPDATE_DATE_FORMAT, sagaUpdateDateFormat);
  yield takeEvery(CONSTANTS.USERS_GET_SESSIONS, sagaGetSessions);
  yield takeEvery(CONSTANTS.USERS_DELETE_SESSION, sagaDeleteSession);
}
