import { useSelector } from "react-redux";
import useAuthorizationValidator from 'plugins/TS-lib-utils-public/hooks/useAuthorizationValidator.jsx';

const useInitialUrl = (url = '/admin/dashboard') => {
  //Get url of the original request
  const { checkAccess } = useAuthorizationValidator();
  const user = useSelector((state) => state.userReducer);

  const unallowRoutes = ['/auth/login-page', '/auth/lock-screen-page'];
  const urlInitial = localStorage.getItem('tsd-url');
  return urlInitial && unallowRoutes.includes(urlInitial) === -1
    ? urlInitial
    : user && checkAccess("kilab-module") 
    ? "/admin/kilab/index"
    : url;
};
export default useInitialUrl;
