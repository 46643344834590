import { takeEvery, put, select, call, take } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import CONSTANTS from '../CONSTANTS';
import historyRouter from 'historyRouter';
//Services
import PracticesService from '../../services/PracticesService';
//Actions
import {
  actionSetPractices,
  actionSetPractice,
  actionGetPractices,
  actionGetPractice,
  actionMetricPracticeSelect,
} from '../actions/practicesActions';
import { actionRefreshPermissions } from 'store/actions/usersActions';
//Selectors
import { getUserAuthorization, getPracticeManage } from 'store/selectors/selectors';
import { actionRemoveAvatars } from 'store/actions/avatarsActions';
import { getUser } from 'store/selectors/selectors';
//Translations
import utilTranslations from '../../helpers/utilTranslations';
/**
 * Save or edit a practice
 * @param {json} practice
 */
function* sagaSavePractice({ practice }) {
  const userActive = yield select(getUser);
  //Translations
  const t = utilTranslations(userActive.language || 'de', 'practices');
  try {
    let result = yield PracticesService.save(practice);
    if (result.status >= 400) {
      throw new Error(result.response);
    }
    yield put(actionRemoveAvatars(`practice-${result.data._id}`));
    toast.success(t('alert-success-practice-saved'));
    //yield put(actionRefreshPermissions(result.data._id));
    historyRouter.goBack();
  } catch (error) {
    // console.log(error.message);
    // toast.error(error.message || 'Unauthorized');
  }
}
/**
 * Delete a practice
 * @param {json} practice
 */
function* sagaDeletePractice({ password, id }) {
  const userActive = yield select(getUser);
  //Translations
  const t = utilTranslations(userActive.language || 'de', 'practices');
  try {
    const result = yield call(PracticesService.deletePractice, password, id);
    if (result.status >= 400) {
      throw new Error(result.response);
    }
    toast.success(t('alert-success-practice-deleted'));
    yield put(actionSetPractice(result.data));
  } catch (error) {
    // console.log(error);
    // toast.error(error.message || 'Unauthorized');
  }
}

/**
 * Get a practice
 * @param {json} practice
 */

function* sagaPractice({ id, options }) {
  const userActive = yield select(getUser);
  //Translations
  const t = utilTranslations(userActive.language || 'de', 'practices');
  try {
    const result = yield call(PracticesService.getPractice, id, options);
    if (result.status >= 400) {
      throw new Error(result.response);
    }
    const practice = result.data;
    const practiceManage = yield select(getPracticeManage);

    if (String(practice._id) === String(practiceManage?._id)) {
      yield put(actionMetricPracticeSelect(practice));
    }

    yield put(actionSetPractice(practice));
  } catch (error) {
    toast.error(t('unauthorized'));
    console.log(error);
  }
}
/**
 * Get all practices of active user and set an specific practice by id
 * @param setPractice practice id for be seted
 */
function* sagaPractices({ setPractice }) {
  try {
    const result = yield call(PracticesService.getPractices);
    const practices = result.data;
    if (result.status >= 400) {
      throw new Error(result.response);
    }
    yield put(actionSetPractices(result.data));
    if (setPractice) {
      const practice = !Array.isArray(practices)
        ? null
        : practices.find((p) => p._id === setPractice);
      if (practice) {
        yield put(actionSetPractice(practice));
      }
    }
  } catch (error) {
    console.log(error);
    // toast.error(error.message || 'Unauthorized');
  }
}
/**
 * Get all practitioners of the active practice
 * @deprecated
 */
function* sagaPractitioners() {
  const userActive = yield select(getUser);
  //Translations
  const t = utilTranslations(userActive.language || 'de', 'practices');
  try {
    const practice = yield select(getUserAuthorization);
    const result = yield call(PracticesService.getPractitioners, practice._id);
    if (result.status >= 400) {
      throw new Error(result.response);
    }
  } catch (error) {
    console.log(error);
    toast.error(t('unauthorized'));
  }
}
/**
 * Delete a practitioner of the active practice
 */
function* sagaDeletePractitioner({ id }) {
  const userActive = yield select(getUser);
  //Translations
  const t = utilTranslations(userActive.language || 'de', 'practices');
  try {
    const practice = yield select(getPracticeManage);
    const result = yield call(
      PracticesService.deletePractitioner,
      practice._id,
      id
    );
    if (result.status >= 400) {
      throw new Error(result.response);
    }
    if (!result.data) {
      return toast.warning(t('alert-warning-user-not-found'));
    }
    
    toast.success(t('alert-success-user-removed'));
  } catch (error) {
    console.log(error);
    toast.error(t('unauthorized'));
  }
}
/**
 * Edit a practitioner of the active practice
 */
function* sagaEditPractitioner({ data }) {
  const userActive = yield select(getUser);
  //Translations
  const t = utilTranslations(userActive.language || 'de', 'practices');
  try {
    const practice = yield select(getPracticeManage);
    const result = yield call(
      PracticesService.editPractitioner,
      practice._id,
      data
    );
    if (result.status >= 400) {
      throw new Error(result.response);
    }
    if (result.status === 201) {
      return toast.warning(t('alert-warning-user-not-found'));
    }
    toast.success(t('alert-success-user-saved'));
    yield put(actionGetPractice(practice._id));
    yield put(actionGetPractices());
  } catch (error) {
    console.log(error);
    toast.error(t('unauthorized'));
  }
}
//Invitations
/**
 * Sending a invitations from practice
 * @param {json} invitation
 */
function* sagaSendInvitation({ invitation }) {
  const userActive = yield select(getUser);
  //Translations
  const t = utilTranslations(userActive.language || 'de', 'practices');
  try {
    const practice = yield select(getPracticeManage);
    let result = yield PracticesService.saveInvitation(
      practice._id,
      invitation
    );
    if (result.status >= 400) {
      throw new Error(result.response);
    }
    toast.success(t('alert-success-invitation-sended'));
    yield put(actionGetPractice(practice._id));
    yield take(CONSTANTS.PRACTICE_METRIC_SELECTED);
    historyRouter.push('/admin/practice/users/invitations');
  } catch (error) {
    console.log(error.message);
    toast.error(t('unauthorized'));
  }
}

export function* practicesSaga() {
  console.log('*Main Practices Saga');
  yield takeEvery(CONSTANTS.PRACTICES_GET_LIST, sagaPractices);
  yield takeEvery(CONSTANTS.PRACTICES_DELETE, sagaDeletePractice);
  yield takeEvery(CONSTANTS.PRACTICES_SAVE, sagaSavePractice);
  yield takeEvery(CONSTANTS.PRACTICES_GET, sagaPractice);
  yield takeEvery(CONSTANTS.PRACTITIONERS_GET_LIST, sagaPractitioners);
  yield takeEvery(CONSTANTS.PRACTITIONER_DELETE, sagaDeletePractitioner);
  yield takeEvery(CONSTANTS.PRACTITIONER_EDIT, sagaEditPractitioner);
  yield takeEvery(CONSTANTS.INVITATION_SAVE, sagaSendInvitation);
}
