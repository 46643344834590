import { takeEvery, take, put, call, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
//Redux Actions
import CONSTANTS from '../CONSTANTS';
import {
  actionSetMessages,
  actionSetMessage,
  actionSetMessagesScroll,
  actionSetOutboxScroll,
  actionSetPriorityboxScroll,
  actionGetOutboxMessage,
  actionSetBox,
  actionGetMessages,
  actionOutboxGetMessages,
  actionGetPriorityMessages,
  actionOutboxSetMessages,
  actionSetPriorityBox,
  // actionSetLoadingMsg,
} from '../actions/messagesActions';
//Services
import MessagesService from '../../plugins/TS-lib-utils-public/services/MessagesService.js';
import { getMessages, getUserAuthorization, getUser} from 'store/selectors/selectors';
import { actionRefreshPermissions } from 'store/actions/usersActions';
import { actionGetPractice } from 'store/actions/practicesActions';
import utilTranslations from '../../helpers/utilTranslations';

MessagesService.setBaseUrl('/doctors');
/**
 * Send a menssage
 * @param {object} message
 */
function* sagaSendMessage({ message, refresh_outbox }) {
  const userActive = yield select(getUser);
  let t = utilTranslations(userActive.language || 'de', 'messages-saga');
  try {
    const practice = yield select(getUserAuthorization);    
    message = {
      ...message,
      practice: practice._id ? practice._id : '',
      userRole: practice.role_key ? practice.role_key : '',
    };
    const result = yield call(MessagesService.reply, practice._id, message);

    if (result && result.status >= 400) {
      throw new Error(result.response);
    }

    if (refresh_outbox) {
      const message = result.data.message;
      yield put(actionGetOutboxMessage(message._id));
      yield put(actionOutboxGetMessages());
      yield put(actionSetBox('inbox'));
    }

    toast.success(t('message-has-been-sent'));
  } catch (error) {
    console.log(error);
    toast.error(t('message-not-sent'));
  }
}

/**
 * Delete a message
 * @param {array} messages_id messages id
 */
function* sagaDelMessage({ messages }) {

  //Translation lib
  const userActive = yield select(getUser);
  let t = utilTranslations(userActive.language || 'de', 'messages-saga');

  try {
    const { box } = yield select(getMessages);
    const practice = yield select(getUserAuthorization);
    const result = yield call(
      MessagesService.del,
      practice._id,
      messages,
      box === 'outbox' ? 'outbox' : 'inbox'
    );
    if (result.status === 200) {
      switch (box) {
        case 'inbox':
          yield put(actionGetMessages());
          break;
        case 'outbox':
          yield put(actionOutboxGetMessages());
          break;
        case 'priority':
          yield put(actionGetPriorityMessages());
          break;
        default:
          break;
      }
      yield put(actionSetMessage());
      toast.info( t("message-has-been-deleted") ); //'Message has been deleted');
    }
  } catch (error) {
    console.log(error);
  }
}

/**
 * Get inbox messages
 */
function* sagaMessages({ page, limit, search }) {
  try {
    const practice = yield select(getUserAuthorization);
    const result = yield call(
      MessagesService.inbox,
      practice._id,
      page,
      limit,
      search
    );
    yield put(actionSetMessages(result));
  } catch (error) {
    console.log(error);
  }
}
/**
 * Get one message
 * @param {string} id message id
 */
function* sagaGetMessage({ id }) {
  try {
    const practice = yield select(getUserAuthorization);
    const message = yield call(
      MessagesService.getById,
      practice._id,
      id,
      'inbox'
    );
    yield put(actionSetMessage(message));
  } catch (error) {
    console.log(error);
  }
}

/**
 * Get one message from url params
 * @param {string} id message id
 * @param {string} practiceId practice id
 */
function* sagaGetMessageFromUrlParams({ id, practiceId }) {
  
  //Translation lib
  const userActive = yield select(getUser);
  let t = utilTranslations(userActive.language || 'de', 'messages-saga');

  try {
    const userActive = yield select(getUser);
    if (practiceId !== '' && userActive.practice_active !== practiceId) {
      yield put(actionGetPractice(practiceId));
      yield put(actionRefreshPermissions(practiceId));
      yield take(CONSTANTS.PRACTICES_SET);
    }

    const practice = yield select(getUserAuthorization);
    const message = yield call(
      MessagesService.getById,
      practice._id,
      id,
      'inbox'
    );
    yield put(actionSetMessage(message));

  } catch (error) {
    console.log(error);
    toast.error(t('message-not-loaded'));
  }
}

/**
 * Get outbox messages
 */
function* sagaMessagesOutbox({ page, limit, search }) {

  try {
    const practice = yield select(getUserAuthorization);
    const result = yield call(
      MessagesService.outbox,
      practice._id,
      page,
      limit,
      search
    );
    yield put(actionOutboxSetMessages(result));
  } catch (error) {
    console.log(error);
  }
}
/**
 * Get one message
 * @param {string} id message id
 */
function* sagaGetOutboxMessage({ id }) {
  try {
    const practice = yield select(getUserAuthorization);
    const message = yield call(
      MessagesService.getById,
      practice._id,
      id,
      'outbox'
    );
    yield put(actionSetMessage(message));
  } catch (error) {
    console.log(error);
  }
}

/**
 * Get one message from notifications
 * @param {string} id message id
 */
function* sagaGetMessageNotification({ practiceId, messageId }) {
  try {
    // yield put(actionSetLoadingMsg(true));
    const message = yield call(
      MessagesService.getById,
      practiceId,
      messageId,
      'inbox'
    );
    yield put(actionSetMessage(message));
    yield put(actionRefreshPermissions(practiceId));
    // yield put(actionSetLoadingMsg(false));
  } catch (error) {
    console.log(error);
  }
}

/**
 * Get priority box messages
 */
function* sagaMessagesPriority({ page, limit, search }) {
  try {
    const practice = yield select(getUserAuthorization);
    const result = yield call(
      MessagesService.priority,
      practice._id,
      page,
      limit,
      search
    );
    yield put(actionSetPriorityBox(result));
  } catch (error) {
    console.log(error);
  }
}

function* sagaMessagesScroll({ search }) {
  try {
    const { box, inbox, outbox, prioritybox } = yield select(getMessages);
    const practice = yield select(getUserAuthorization);
    let result = [];
    switch (box) {
      case 'inbox':
        result = yield call(
          MessagesService.inbox,
          practice._id,
          inbox.page + 1,
          20,
          search
        );
        yield put(actionSetMessagesScroll(result));
        break;
      case 'outbox':
        result = yield call(
          MessagesService.outbox,
          practice._id,
          outbox.page + 1,
          20,
          search
        );
        yield put(actionSetOutboxScroll(result));
        break;
      case 'priority':
        result = yield call(
          MessagesService.priority,
          practice._id,
          prioritybox.page + 1,
          20,
          search
        );
        yield put(actionSetPriorityboxScroll(result));
        break;
      default:
        break;
    }
  } catch (error) {
    console.log(error);
  }
}
/**
 * Set message important
 * @param {array} messages array of id messages
 */
function* sagaRefreshBoxes() {
  try {
    yield put(actionGetMessages(1));
    yield put(actionOutboxGetMessages(1));
    yield put(actionGetPriorityMessages(1));
    // yield put(actionSetMessage());
  } catch (error) {
    console.log(error);
  }
}
/**
 * Set message important
 * @param {array} messages array of id messages
 */
function* sagaSetPriority({ messages }) {

  //Translation lib
  const userActive = yield select(getUser);
  let t = utilTranslations(userActive.language || 'de', 'messages-saga');

  try {
    const { box } = yield select(getMessages);
    const practice = yield select(getUserAuthorization);
    const result = yield call(
      MessagesService.setPriority,
      practice._id,
      messages
    );

    if (result.status === 200 && result.data.status === 'success') {
      let result;
      switch (box) {
        case 'inbox':
          result = yield call(MessagesService.inbox, practice._id, 1);
          yield put(actionSetMessages(result));
          break;
        case 'outbox':
          result = yield call(MessagesService.outbox, practice._id, 1);
          yield put(actionOutboxSetMessages(result));
          break;
        case 'priority':
          break;
        default:
          break;
      }
      result = yield put(actionGetPriorityMessages(1));
      yield put(actionSetPriorityBox(result));
      toast.success( t("priority-updated") ); //'Priority updated');
    }
  } catch (error) {
    console.log(error);
  }
}

export function* messagesSaga() {
  console.log('*Main Messages Saga');
  yield takeEvery(CONSTANTS.MESSAGES_GET_SCROLL, sagaMessagesScroll);
  yield takeEvery(CONSTANTS.MESSAGES_GET_FROM_URL, sagaGetMessageFromUrlParams);
  yield takeEvery(CONSTANTS.MESSAGES_GET_INBOX, sagaMessages);
  yield takeEvery(CONSTANTS.MESSAGES_GET_OUTBOX, sagaMessagesOutbox);
  yield takeEvery(CONSTANTS.MESSAGES_GET_MSG, sagaGetMessage);
  yield takeEvery(CONSTANTS.MESSAGES_GET_OUTBOX_MSG, sagaGetOutboxMessage);
  yield takeEvery(CONSTANTS.MESSAGES_DEL_MSG, sagaDelMessage);
  yield takeEvery(CONSTANTS.MESSAGES_SEND_MSG, sagaSendMessage);
  yield takeEvery(CONSTANTS.MESSAGES_GET_PRIORITY, sagaMessagesPriority);
  yield takeEvery(CONSTANTS.MESSAGES_SET_PRIORITY, sagaSetPriority);
  yield takeEvery(CONSTANTS.MESSAGES_REFRESH_BOXES, sagaRefreshBoxes);
  yield takeEvery(
    CONSTANTS.MESSAGES_GET_MSG_NOTIFICATION,
    sagaGetMessageNotification
  );
  //yield takeEvery(CONSTANTS.MESSAGES_GET_PRIORITY, sagaMessagesPriority);
}
