import CONSTANTS from '../CONSTANTS';
let langs = ['de', 'ru', 'en', 'es'];
let langBrowser = (navigator.language || navigator.userLanguage).substr(0, 2);
let langDefault = langs.includes(langBrowser) ? langBrowser : langs[0];

const initState = {
  isAuth: false,
  key: null,
  uri: null,
  auth_2fa: '',
  loading: false,
  language: langDefault,
  dateFormat: 'YYYY-MM-DD',
  practices: {},
  sessions: [],
  logoutModalActive: false
};
const userReducer = (state = initState, action) => {
  switch (action.type) {
    case CONSTANTS.USERS_SET:
      if (typeof action.user !== 'undefined') {
        return { ...state, ...action.user, isAuth: true };
      }
      return initState;
    case CONSTANTS.USERS_LOGOUT_CLEAR:
      return initState;
    case CONSTANTS.USERS_SHOW_LOGOUT_MODAL:
      return {...state, logoutModalActive: action.val};
    case CONSTANTS.USERS_SET_LANG:
      return { ...state, language: action.language };
    case CONSTANTS.USERS_SET_DATE_FORMAT:
      return { ...state, dateFormat: action.dateFormat };    
    case CONSTANTS.AUTH2FA_SET:
      return {
        ...state,
        key: action.qr.key,
        uri: action.qr.uri,
      };
    case CONSTANTS.USERS_LOADING:
      return { ...state, loading: action.loading };
    case CONSTANTS.USERS_SET_SESSIONS:
      return { ...state, sessions: action.sessions };
    default:
      return state;
  }
};

export default userReducer;
