import CONSTANTS from '../CONSTANTS';

const initialState = {

  kilabData: [],
  laborData: []
}
const statsReducer = (

  state = initialState,
  action
) => {

  switch (action.type) {
    
    case CONSTANTS.LAB_EARLY_STORE_PRACTICE_METRICS_REDUCER: 
      return {...state, kilabData: action.data}
    case CONSTANTS.LAB_STORE_PRACTICE_METRICS: 
      return {...state, laborData: action.data}
    default:
      return state;
  }
};

export default statsReducer;