import CONSTANTS from '../CONSTANTS';

export const actionGetStudy = (reportId, practiceId, loadAiComment = false) => ({
  type: CONSTANTS.LAB_GET_STUDY,
  reportId, practiceId, loadAiComment
});

export const actionSetStudy = (study) => ({
  type: CONSTANTS.LAB_SET_STUDY,
  study
});

export const actionUpdateReview = (reportId, review, hl7Id, templateName, doctorId, practiceId, aiFlag, isAi, skipModal, makeDraft) => ({
  type: CONSTANTS.LAB_UPDATE_REVIEW,
  reportId, review, templateName, doctorId, practiceId, aiFlag, skipModal, isAi, makeDraft, hl7Id
});

export const actionSendReview = (reportId, review, hl7Id, templateName, doctorId, practiceId, billing, aiFlag, isAi) => ({
  type: CONSTANTS.LAB_SEND_REVIEW,
  reportId, review, templateName, doctorId, practiceId, billing, aiFlag, isAi, hl7Id
});

export const actionGetLabStudies = (options) => ({
  type: CONSTANTS.LAB_GET_STUDIES,
  options
});

export const actionSetLabStudies = (studies) => ({
  type: CONSTANTS.LAB_SET_STUDIES,
  studies
});

export const actionMarkAIComment = (reportId, doctorId, practiceId) => ({
  type: CONSTANTS.LAB_READ_AI_COMMENT,
  reportId, doctorId, practiceId
});

export const actionDeleteDraft = (reportId, reviewId, doctorId, practiceId) => ({
  type: CONSTANTS.LAB_DELETE_DRAFT,
  reportId, doctorId, practiceId, reviewId
});

export const actionSetLoadingAiComment = (value) => ({
  type: CONSTANTS.LAB_LOADING_AI_COMMENT,
  value
});

export const actionSetCanVote = (value) => ({
  type: CONSTANTS.LAB_CAN_VOTE,
  value
});

export const actionSetLoadingStudy = (value) => ({
  type: CONSTANTS.LAB_LOADING_STUDY,
  value
});

export const actionSetLoadingStudyList = (value) => ({
  type: CONSTANTS.LAB_LOADING_STUDY_LIST,
  value
});

export const actionSetSentModal = (value) => ({
  type: CONSTANTS.LAB_SHOW_SENT_MODAL,
  value
});

export const actionSaveTemplateLocalData = (name, content) => ({
  type: CONSTANTS.LAB_SAVE_TEMPLATE_LOCAL_DATA,
  name, content
});

export const actionSetSearchQuery = (search) => ({
  type: CONSTANTS.LAB_SET_SEARCH_QUERY,
  search
});

export const actionSubmitLabSurvey = (reportId, doctorId, practiceId, surveyData) => ({
  type: CONSTANTS.LAB_SUBMIT_SURVEY,
  reportId,
  doctorId,
  practiceId,
  surveyData
})
