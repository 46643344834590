//Helper to render html entities correctly
export const sanitizeHtmlReview = (html) => {
    //Decode HTMLEntities
    let txt = document.createElement("textarea");
    txt.innerHTML = html;
    //Delete HTML tags
    return txt.value.replace(/(?![^>]+\b(?:item|range)=)(<[/]*[a-z]+.*?>)/gi, "");
};

//Helper to filter control keys from keyboard
export const isValidInput = (e) => {
    let code = e.which ? e.which : e.keyCode;
    return !(
        code == 16 || 
        code == 17 ||
        code == 18 || 
        code == 19 || 
        code == 20 || 
        code == 27 ||
        code == 45 || 
        code == 91 || 
        code == 93 ||
        (code >= 35 && code <= 40) ||
        (code >= 33 && code <= 34 ) ||
        (code >= 112 && code <= 123) ||
        (code >= 144 && code <= 145 )
    );
}